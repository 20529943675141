import type { Dispatch, ReactNode } from 'react';
import type { Range } from 'slate';

import React, { useContext, createContext, useReducer } from 'react';

import { MenuActionTypes } from '@ui/MarkdownEditor/enums';

interface UrlMenuState {
  open: boolean;
  range: Range | null;
  target?: React.RefObject<HTMLElement>;
}

interface UrlMenuOpenAction {
  range: UrlMenuState['range'];
  target: UrlMenuState['target'];
  type: MenuActionTypes.open;
}

interface UrlMenuCloseAction {
  type: MenuActionTypes.close;
}

type UrlMenuAction = UrlMenuCloseAction | UrlMenuOpenAction;

const initial: UrlMenuState = {
  open: false,
  range: null,
};

const useUrlMenuReducer = () => {
  const reducer = (state: UrlMenuState, action: UrlMenuAction) => {
    switch (action.type) {
      case MenuActionTypes.open:
        return { open: true, range: action.range, target: action.target };
      case MenuActionTypes.close:
        return { ...state, open: false };
      default:
        return state;
    }
  };

  return useReducer(reducer, initial);
};

const UrlMenuContext = createContext([initial, () => {}] as [UrlMenuState, Dispatch<UrlMenuAction>]);

export const UrlMenuProvider = ({ children }: { children: ReactNode }) => {
  const value = useUrlMenuReducer();

  return <UrlMenuContext.Provider value={value}>{children}</UrlMenuContext.Provider>;
};

export const useUrlMenu = () => useContext(UrlMenuContext);
export default useUrlMenu;
