import {
  convertToBlockquote,
  convertToCalloutError,
  convertToCalloutInfo,
  convertToCalloutSuccess,
  convertToCalloutWarning,
} from './operations';

export const slashMenu = {
  Blockquote: { icon: 'quotes', basic: true, transform: convertToBlockquote },
  'Callout - Success': { icon: 'callout-success', transform: convertToCalloutSuccess },
  'Callout - Info': { icon: 'callout-info', transform: convertToCalloutInfo },
  'Callout - Warning': { icon: 'callout-warning', transform: convertToCalloutWarning },
  'Callout - Error': { icon: 'callout-error', transform: convertToCalloutError },
};

export default slashMenu;
