import type { Range } from 'slate';

import { Editor, Transforms } from 'slate';

import type { JsxCommentElement } from '@ui/MarkdownEditor/types';

import { isJsxComment, type } from './shared';

export const expandComment = (editor: Editor, at: Range) => {
  const atRef = Editor.rangeRef(editor, at);
  if (!atRef.current) return false;

  try {
    Transforms.unwrapNodes(editor, { at, match: isJsxComment });
    Transforms.wrapNodes(editor, { type } as JsxCommentElement, { at: atRef.current, split: true, mode: 'highest' });

    return true;
  } finally {
    atRef.unref();
  }
};
