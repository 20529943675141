export const tooltipItems = [
  {
    block: 'Link',
    shortcut: '[Title](URL)',
  },
  {
    block: 'Heading 1',
    shortcut: '#',
  },
  {
    block: 'Heading 2',
    shortcut: '##',
  },
  {
    block: 'Heading 3',
    shortcut: '###',
  },
  {
    block: 'Blockquote',
    shortcut: '>',
  },
  {
    block: 'Callout - Success',
    shortcut: '>👍',
  },
  {
    block: 'Callout - Info',
    shortcut: '>📘',
  },
  {
    block: 'Callout - Warning',
    shortcut: '>🚧',
  },
  {
    block: 'Callout - Error',
    shortcut: '>❗️',
  },
  {
    block: 'Bulleted List',
    shortcut: '-',
  },
  {
    block: 'Numbered List',
    shortcut: '1.',
  },
  {
    block: 'Check List',
    shortcut: '- [ ]',
  },
  {
    block: 'Code',
    shortcut: '```',
  },
  {
    block: 'Divider',
    shortcut: '---',
  },
  {
    block: 'Image',
    shortcut: '![Alt text](URL)',
  },
  {
    block: 'Recipe',
  },
  {
    block: 'Table',
  },
  {
    block: 'YouTube',
  },
  {
    block: 'GitHub Gist',
  },
  {
    block: 'PDF',
  },
  {
    block: 'JSFiddle',
  },
  {
    block: 'Custom HTML',
  },
  {
    block: 'Iframe',
  },
  {
    block: 'Reusable Content',
    label: 'Create New',
    description: 'The contents of this block will be synced between all pages',
  },
];
