import Component from './Component';
import normalizeNode from './normalizeNode';
import { deserialize, serialize } from './serialize';
import { type } from './shared';

const JsxComment = {
  Component,
  deserialize,
  normalizeNode,
  serialize,
  type,
};

export default JsxComment;
