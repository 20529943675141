import React, { useCallback, useRef } from 'react';
import { Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';

import Button from '@ui/Button';
import Dropdown from '@ui/Dropdown';
import { ImageMenuActionTypes } from '@ui/MarkdownEditor/enums';
import Menu from '@ui/Menu';
import Toggle from '@ui/Toggle';

import { Figcaption } from '../blocks';

import ResizeEditor from './ResizeEditor';
import classes from './style.module.scss';
import UploadEditor from './UploadEditor';
import useImageMenu from './useImageMenu';

const Toolbar = () => {
  const editor = useSlateStatic();
  const [{ node }, dispatch] = useImageMenu();

  const ref = useRef<HTMLDivElement>(null);

  const submitBordered = useCallback(
    border => {
      if (!node) return;

      const path = ReactEditor.findPath(editor, node);
      if (!path) return;

      // @ts-ignore `border` doesn't exist. fixme?
      Transforms.setNodes(editor, { border, align: node.align || 'center' }, { at: path });
    },
    [editor, node],
  );

  const onChangeBorder = useCallback(() => {
    if (!node) return;

    submitBordered(!node.border);
    dispatch({ type: ImageMenuActionTypes.close });
  }, [node, submitBordered, dispatch]);

  const insertCaption = useCallback(() => {
    if (!node) return;

    const path = ReactEditor.findPath(editor, node);

    Transforms.setNodes(editor, { align: 'center' }, { at: path });

    Figcaption.operations.insertFigcaption(editor, path);

    dispatch({ type: ImageMenuActionTypes.close });
    ReactEditor.focus(editor);
  }, [dispatch, editor, node]);

  return (
    <Menu ref={ref} className={classes.ImageToolbarMenu}>
      <Dropdown justify="start">
        <Button data-testid="upload-editor-button" kind="minimum" size="sm" text>
          <i className="icon-settings" />
        </Button>
        <UploadEditor node={node} />
      </Dropdown>
      {!node?.isInline && (
        <>
          <div className={classes['ImageToolbar-Divider']} />
          <Dropdown justify="start">
            <Button kind="minimum" size="sm" text>
              <span>Size</span>
              <i className="icon-chevron-down" />
            </Button>
            <ResizeEditor />
          </Dropdown>
          <div className={classes['ImageToolbar-Divider']} />
          <Button id="image-caption-settings" kind="minimum" onPointerUp={insertCaption} size="sm" text>
            Caption
          </Button>
          <div className={classes['ImageToolbar-Divider']} />
          <div className={classes['ImageToolbar-ImageBorder']}>
            <span>Border</span>
            <Toggle checked={node?.border} onChange={onChangeBorder} type="toggle" />
          </div>
        </>
      )}
    </Menu>
  );
};
export default Toolbar;
