import * as rdmd from '@readme/markdown';

import emptyNode from '../../emptyNode';
import {
  Blockquote,
  Code,
  CodeTabs,
  Div,
  Embed,
  EmbedBlock,
  Emoji,
  FaEmoji,
  Figcaption,
  Figure,
  GlossaryTerm,
  HorizontalRule,
  Html,
  Image,
  ImageBlock,
  ImageEmoji,
  InlineHtml,
  JsxComment,
  Link,
  ListItem,
  Recipe,
  TableCell,
  TableHeader,
  TableRow,
  Variable,
} from '../blocks';
import { blocksByType as blocks, leavesByType as leaves } from '../byType';
import { Emphasis, Strong, InlineCode, Delete } from '../leaves';

import serializeForNow from './serializeForNow';

const blockNodes = [Image.type, Embed.type];

const mdastToSlate = (node, doc, opts = {}) => {
  const { useMDX, renderingLibrary = rdmd } = opts;
  const deeper = n => (n.children ? n.children.flatMap(child => mdastToSlate(child, doc, opts)) : []);
  const args = [node, deeper, { ...opts, doc }];

  switch (node.type) {
    case 'root':
      if (node.children.length === 0) {
        return [emptyNode()];
      }

      return deeper(node).reduce((lines, line) => {
        // @note: remark-parse doesn't have different types for html blocks or
        // html inlines. But, block level html is a valid markdown block. So if
        // you have the markdown: `<p>some text</p>`, that won't get wrapped in
        // an additional paragraph.
        // eslint-disable-next-line no-param-reassign
        if (line.text || line.type === Variable.type) line = { type: 'paragraph', children: [line] };

        lines.push(line);

        return lines;
      }, []);
    case 'escape':
      return { text: `\\${node.value}` };
    case 'text':
      return { text: node.value };
    case Emphasis.type:
    case Strong.type: {
      return leaves[node.type].deserialize(...args);
    }
    case 'inlineCode':
      return [{ text: `${InlineCode.decorator}${node.value}${InlineCode.decorator}` }];
    case 'delete':
      return [{ text: Delete.decorator }, ...deeper(node), { text: Delete.decorator }];
    case 'readme-glossary-item':
      return (useMDX ? GlossaryTerm : Variable).deserialize(...args);
    case 'readme-variable':
      return Variable.deserialize(...args);
    case 'mdxTextExpression':
      return node.data.estree.comments.length > 0
        ? JsxComment.deserialize(...args)
        : serializeForNow(node, { inline: true, renderingLibrary });
    case 'mdxFlowExpression':
      return node.data.estree.comments.length > 0
        ? JsxComment.deserialize(...args)
        : serializeForNow(node, { renderingLibrary });
    case 'definition':
      return serializeForNow(node, { renderingLibrary });
    case 'linkReference':
    case 'imageReference':
      return serializeForNow(node, { inline: true, renderingLibrary });
    case Link.type:
      return Link.deserialize(...args);
    case 'html':
      return InlineHtml.deserialize(...args);
    case 'html-block':
      return Html.deserialize(...args);
    case 'embed-block':
      return EmbedBlock.deserialize(...args);
    case 'image-block':
      return ImageBlock.deserialize(...args);
    case Code.type:
    case CodeTabs.type:
      return CodeTabs.deserialize(...args);
    case 'rdme-callout':
    case 'blockquote':
    case 'embed':
    case 'heading':
    case 'list':
    case 'listItem':
    case 'table':
    case TableRow.rdmdType:
    case TableHeader.type:
    case TableCell.rdmdType:
    case TableCell.type:
    case 'tableHead':
    case 'thematicBreak': {
      let { type } = node;
      if (type === 'listItem') type = ListItem.type;
      if (type === 'thematicBreak') type = HorizontalRule.type;
      if (type === 'rdme-callout') type = Blockquote.type;
      if (type === 'tableCell') type = TableCell.type;
      if (type === 'tableRow') type = TableRow.type;
      if (type === 'tableHead') type = TableCell.type;
      args[0].type = type;

      return blocks[type].deserialize(...args);
    }
    case 'tableau':
      return blocks.table.deserialize(...args);
    case FaEmoji.rdmdType:
      return FaEmoji.deserialize(...args);
    case 'figure':
      return Figure.deserialize(...args);
    case 'figcaption':
      return Figcaption.deserialize(...args);
    case 'image': {
      return ImageEmoji.isMdImageEmoji(node) ? ImageEmoji.deserialize(...args) : Image.deserialize(...args);
    }
    case 'div': {
      switch (node?.data?.hName) {
        case Recipe.rdmdType:
          return Recipe.deserialize(...args);
        default:
          return Div.deserialize(...args);
      }
    }
    case Recipe.rdmdType: {
      return Recipe.deserialize(...args);
    }
    case 'break':
      return {
        text: '\n',
      };
    case 'paragraph':
      // @note: If the paragraph is just an image, let's just return the image
      // node to avoid invalid DOM nesting in the editor.
      if (node.children.length === 1 && blockNodes.includes(node.type) && !Emoji.isMdEmoji(node)) {
        return deeper(node);
      }
      return { type: node.type, children: deeper(node) };
    case 'rdme-pin':
      return deeper(node);
    case 'emoji':
      return {
        text: node.value,
      };
    default: {
      if (node.type in blocks && blocks[node.type].deserialize) {
        return blocks[node.type].deserialize(...args);
      }

      // eslint-disable-next-line no-console
      console.warn(`Unhandled node type! ${node.type}`);

      return serializeForNow(node, { renderingLibrary });
    }
  }
};

export default mdastToSlate;
