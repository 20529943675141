import React from 'react';

import Flex from '@ui/Flex';
import Input from '@ui/Input';

import menuClasses from '../../style.module.scss';
import classes from '../style.module.scss';

interface Props {
  handleOnBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  handleOnKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  urlEntered: string;
  urlLabel: string;
  urlValidationMessage?: string;
}

const EmbedURLInput = ({
  urlLabel,
  placeholder,
  urlEntered,
  handleOnBlur,
  handleOnKeyDown,
  urlValidationMessage,
}: Props) => (
  <Flex
    className={menuClasses['EditorMenu-label']}
    data-testid="editor-embed-urllabel"
    gap="sm"
    layout="col"
    tag="label"
  >
    <div>{urlLabel}</div>
    <Input
      className={classes['Embed-Input']}
      data-testid="editor-embed-url-upload"
      onBlur={handleOnBlur}
      onKeyDown={handleOnKeyDown}
      placeholder={placeholder}
      size="sm"
      type="url"
      value={urlEntered}
    />
    {!!urlValidationMessage && <div className={classes['Settings-Validation']}>{urlValidationMessage}</div>}
  </Flex>
);

export default EmbedURLInput;
