import { type as imageType } from '../Image/shared';

import { type } from './shared';

export const deserialize = ({ position, ...node }) => {
  return {
    ...node,
    type,
    children: [{ text: '' }],
  };
};

export const serialize = ({ children, ...node }) => ({
  ...node,
  type: imageType,
});
