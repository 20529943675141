import type { Node } from 'slate';

import { Editor, Element, Point, Range } from 'slate';

import type { ReusableContentElement } from '@ui/MarkdownEditor/types';

export const type = 'reusable-content' as ReusableContentElement['type'];
export const isReusableContent = (node: Node) => Element.isElementType(node, type);

export const isAtEdgeOfReusableContent = (editor: Editor, { edge }: { edge: 'end' | 'start' }) => {
  if (!editor.selection) return false;

  const reusableContentAbove = Editor.above(editor, { match: isReusableContent });
  if (!reusableContentAbove) return false;

  const [reusableContent, reusableContentPath] = reusableContentAbove;

  return (
    isReusableContent(reusableContent) &&
    Range.isCollapsed(editor.selection) &&
    Point.equals(editor.selection.anchor, Editor[edge](editor, reusableContentPath))
  );
};
