import React, { useCallback, useRef } from 'react';
import { Editor, Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';

import type { DropdownRef } from '@ui/Dropdown';
import Dropdown from '@ui/Dropdown';
import { UploadEditor, useImageMenu } from '@ui/MarkdownEditor/editor/ImageMenu';
import type { ImageElement, ImageBlock } from '@ui/MarkdownEditor/types';
import Spinner from '@ui/Spinner';

import EmptyBlock, { SettingsButton } from '../EmptyBlock';
import { isFigure } from '../Figure/shared';

import classes from './style.module.scss';

interface Props {
  element: ImageBlock | ImageElement;
}

const offset = [0, -5];

const Placeholder = ({ element }: Props) => {
  const editor = useSlateStatic();
  const [{ node, uploads }] = useImageMenu();
  const dropdownRef = useRef<DropdownRef>(null);

  const text = uploads.get(element)?.isImageLoading ? '' : 'Choose Image';
  const icon = uploads.get(element)?.isImageLoading ? <Spinner /> : 'icon-image';

  const onClick = useCallback(() => {
    if (node === element) return;

    const path = ReactEditor.findPath(editor, element);
    const figure = Editor.above(editor, { at: path, match: isFigure });

    Transforms.select(editor, figure ? figure[1] : path);
  }, [editor, element, node]);

  const open = useCallback(() => dropdownRef?.current?.toggle(true), []);
  const close = useCallback(() => dropdownRef?.current?.toggle(false), []);

  return (
    <EmptyBlock icon={icon} onClick={onClick} testId="empty-image-component" text={text}>
      <Dropdown
        ref={dropdownRef}
        appendTo={document.body}
        justify="start"
        offset={offset}
        open={node === element}
        trigger="manual"
      >
        <SettingsButton className={classes.ImagePlaceholderButton} onClick={open} />
        <UploadEditor close={close} node={element} />
      </Dropdown>
    </EmptyBlock>
  );
};

export default Placeholder;
