import type { ParagraphElement, ReadmeBlock } from './types';

import Paragraph from './editor/blocks/Paragraph';

const emptyNode = <NodeType extends ReadmeBlock = ParagraphElement>(props: Partial<NodeType> = {}): NodeType => {
  const node = {
    ...props,
  };

  node.children ??= [{ text: '' }];
  node.type ??= Paragraph.type;

  return node as NodeType;
};

export default emptyNode;
