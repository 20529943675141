import type { RenderElementProps } from 'slate-react';

import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Transforms } from 'slate';
import { useSlateStatic, ReactEditor } from 'slate-react';

import classy from '@core/utils/classy';

import SelectionWrapper from '@ui/MarkdownEditor/editor/SelectionWrapper';
import type { ImageAlignValues } from '@ui/MarkdownEditor/enums';
import { ImageMenuActionTypes } from '@ui/MarkdownEditor/enums';
import type { ImageBlock } from '@ui/MarkdownEditor/types';

import { isEmpty } from '../../utils';

import FileInput from './FileInput';
import Placeholder from './Placeholder';
import classes from './style.module.scss';

interface ImageProps extends RenderElementProps {
  element: ImageBlock;
}

// eslint-disable-next-line jsx-a11y/alt-text
const Img = (props: JSX.IntrinsicElements['img'] & { align?: ImageAlignValues }) => <img {...props} />;

const Image = ({ attributes, children, element }: ImageProps) => {
  const editor = useSlateStatic();
  const [, dispatch] = editor.imageMenu;
  const { editCaption, isInline, url, alt, title, align, width, border } = element;
  const caption = !isEmpty(element);
  const fileInput = <FileInput element={element} />;

  const onClick = useCallback(() => {
    const path = ReactEditor.findPath(editor, element);

    Transforms.select(editor, path);
    dispatch({ type: ImageMenuActionTypes.open, payload: { node: element } });
  }, [dispatch, editor, element]);

  return url ? (
    isInline ? (
      <span {...attributes} style={{ display: 'inline-block' }}>
        <SelectionWrapper element={element} onClick={onClick}>
          <Img
            alt={alt}
            className={classy(classes.Image, classes.Image_inline)}
            data-image-menu-target
            data-image-node
            data-testid="editor-image-for-test"
            src={url}
            title={title}
          />
          {fileInput}
        </SelectionWrapper>
      </span>
    ) : (
      <div {...attributes} className="p">
        <figure>
          <SelectionWrapper element={element} onClick={onClick}>
            <Img
              align={align}
              alt={alt}
              className={classy(classes.Image, border && 'border')}
              data-image-menu-target
              data-image-node
              data-testid="editor-image-for-test"
              src={url}
              title={title}
              width={width}
            />
          </SelectionWrapper>
          {editCaption || caption ? (
            <figcaption>
              {!caption ? (
                <span
                  data-slate-placeholder={true}
                  style={{
                    position: 'absolute',
                    pointerEvents: 'none',
                    width: '100%',
                    maxWidth: '100%',
                    display: 'block',
                    opacity: '0.333',
                    userSelect: 'none',
                    textAlign: 'center',
                    textDecoration: 'none',
                  }}
                >
                  Write a caption…
                </span>
              ) : null}
              {children}
            </figcaption>
          ) : null}
        </figure>
        {fileInput}
      </div>
    )
  ) : (
    <SelectionWrapper blockType={`${element.type}-empty`} {...attributes} element={element}>
      <Placeholder element={element} />
      {/** since this is no longer a voidnoid, children is no longer just a spacer */}
      <span style={{ display: 'none' }}>{children}</span>
      {fileInput}
    </SelectionWrapper>
  );
};

Image.propTypes = {
  attributes: PropTypes.object,
  children: PropTypes.node.isRequired,
  element: PropTypes.object,
};

export default Image;
