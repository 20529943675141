import type { NodeEntry, Node } from 'slate';

import { Editor, Transforms } from 'slate';

import type { JsxCommentElement, Normalizer } from '@ui/MarkdownEditor/types';

import { isJsxCommentToken } from '../JsxCommentToken/shared';

import { expandComment } from './operations';
import { isJsxComment } from './shared';

/* @func validCommentTokens
 * Find a matching pair of comment tags
 */
const validCommentTokens = (editor: Editor, [, path]: NodeEntry<JsxCommentElement>) => {
  const tokens = [...Editor.nodes(editor, { at: path, match: isJsxCommentToken })];
  return !!(tokens[0][0].edge === 'start' && tokens.find(([t]) => t.edge === 'end'));
};

/* @func unwrap
 * Validate that the comment has matching comment tags. If it doesn't have a
 * leading one, unwrap it. If it doesn't have a trailing one, look for a
 * trailing one.
 */
const unwrap: Normalizer =
  next =>
  (editor, [node, path]) => {
    if (!isJsxComment(node) || validCommentTokens(editor, [node, path])) return next();

    const at = {
      anchor: Editor.end(editor, path),
      focus: Editor.end(editor, []),
    };
    const startToken = Editor.nodes(editor, {
      at,
      match: n => isJsxCommentToken(n) && n.edge === 'start',
    }).next().value;
    const endToken = Editor.nodes(editor, {
      at,
      match: n => isJsxCommentToken(n) && n.edge === 'end',
    }).next().value;

    if (!startToken) {
      Transforms.unwrapNodes(editor, { at: path, match: isJsxComment });
      // eslint-disable-next-line consistent-return
      return;
    }

    return expandComment(editor, {
      anchor: Editor.start(editor, path),
      focus: Editor.end(editor, endToken[1]),
    })
      ? null
      : next();
  };

/* @func splitEdges
 * If content is inserted before a leading comment tag, or after a trailing
 * one, split it out onto a new line.
 */
const splitEdges: Normalizer =
  next =>
  // eslint-disable-next-line consistent-return
  (editor, [node, path]) => {
    if (!isJsxComment(node)) return next();

    const found = ['start', 'end'].find(edge => {
      const match = (n: Node) => isJsxCommentToken(n) && n.edge === edge;
      const token = Editor.nodes(editor, {
        at: path,
        match,
        reverse: edge === 'end',
      }).next().value;
      if (!token) {
        return false;
      }

      const point = Editor[edge === 'start' ? 'before' : 'after'](editor, token[1]);
      if (!point) {
        return false;
      }

      const at = Editor.rangeRef(
        editor,
        edge === 'start'
          ? {
              anchor: Editor.start(editor, path),
              focus: point,
            }
          : {
              anchor: point,
              focus: Editor.end(editor, path),
            },
        { affinity: 'inward' },
      );
      if (!at.current) return false;

      try {
        const string = Editor.string(editor, at.current);
        if (string === '') return false;

        // eslint-disable-next-line consistent-return
        Editor.withoutNormalizing(editor, () => {
          if (!at.current) return false;
          Transforms.splitNodes(editor, { at: edge === 'start' ? at.current.focus : at.current.anchor, always: true });
          Transforms.unwrapNodes(editor, { at: at.current, match: isJsxComment, split: true });
          Transforms.select(editor, at.current.focus);
        });
      } finally {
        at.unref();
      }

      return true;
    });

    if (!found) return next();
  };

export default [unwrap, splitEdges];
