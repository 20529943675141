import type { NodeEntry, Location, Range, Node } from 'slate';

import { Editor, Path, Point, Text, Transforms } from 'slate';

import type { JsxCommentElement, JsxCommentTokenElement } from '@ui/MarkdownEditor/types';

import { expandComment } from '../JsxComment/operations';
import { isJsxComment } from '../JsxComment/shared';

import { isJsxCommentToken, type } from './shared';

const maybeExpandComment = (editor: Editor, at: Location) => {
  const range: Range = {
    anchor: Editor.end(editor, at),
    focus: Editor.end(editor, []),
  };
  const token = Editor.nodes(editor, {
    at: range,
    match: n => isJsxCommentToken(n) && n.edge === 'end',
  }).next().value;

  if (!token) return;

  expandComment(editor, {
    anchor: Editor.start(editor, at),
    focus: Editor.end(editor, token[1]),
  });
};

const maybeContractComment = (editor: Editor, comment: NodeEntry<JsxCommentElement>) => {
  let walker: Point | undefined = Editor.end(editor, comment[1]);
  const range = Editor.range(editor, comment[1]);

  const queue: Range[] = [];

  for (const [node, path] of Editor.nodes(editor, {
    at: range,
    match: isJsxCommentToken,
    reverse: true,
  })) {
    if (node.edge === 'end') {
      const after = Editor.after(editor, Editor.end(editor, path));

      if (after && walker && !Point.equals(after, walker)) {
        queue.push(
          JSON.parse(
            JSON.stringify({
              anchor: after,
              focus: walker,
            }),
          ),
        );
      }
    } else {
      walker = Editor.before(editor, Editor.start(editor, Path.parent(path)));
    }
  }

  queue.forEach(at => {
    Transforms.unwrapNodes(editor, {
      at,
      match: isJsxComment,
      split: true,
    });
  });
};

const maybeWrapComment = (editor: Editor, at: Location) => {
  const isLonelyStartToken = (node: Node, path: Path): node is JsxCommentTokenElement => {
    return isJsxCommentToken(node) && node.edge === 'start' && !Editor.above(editor, { at: path, match: isJsxComment });
  };

  const start = Editor.nodes(editor, {
    at: {
      anchor: Editor.start(editor, []),
      focus: Editor.start(editor, at),
    },
    match: isLonelyStartToken,
  }).next().value;

  if (start) {
    Transforms.wrapNodes(editor, { type: 'jsx-comment' } as JsxCommentElement, {
      at: {
        anchor: Editor.start(editor, start[1]),
        focus: Editor.end(editor, at),
      },
    });
  }
};

export const wrapCommentToken = (editor: Editor, { at = editor.selection, edge = 'start' } = {}) => {
  if (!at) return false;

  const atRef = Editor.rangeRef(editor, at);
  if (!atRef.current) return false;

  try {
    Transforms.wrapNodes(editor, { type, edge } as JsxCommentTokenElement, {
      at: atRef.current,
      split: true,
      match: Text.isText,
    });

    if (edge === 'start') {
      maybeExpandComment(editor, atRef.current);
    } else {
      const comment = Editor.above(editor, { at: atRef.current, match: isJsxComment });

      if (comment) {
        maybeContractComment(editor, comment);
      } else {
        maybeWrapComment(editor, atRef.current);
      }
    }

    return true;
  } finally {
    atRef.unref();
  }
};
