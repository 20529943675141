import type { Editor } from 'slate';

import React, { useLayoutEffect, useState, useCallback, useEffect } from 'react';
import { Node } from 'slate';
import { useSlateStatic, ReactEditor } from 'slate-react';

import useEventListener from '@core/hooks/useEventListener';

import Input from '@ui/Input';
import { Code, CodeTabs } from '@ui/MarkdownEditor/editor/blocks';
import { MenuActionTypes } from '@ui/MarkdownEditor/enums';
import type { CodeTabsElement } from '@ui/MarkdownEditor/types';
import Menu from '@ui/Menu';
import MenuHeader from '@ui/Menu/Header';
import MenuItem from '@ui/Menu/Item';

import MenuDropdown from '../MenuDropdown';

import classes from './style.module.scss';
import useCodeSettings from './useCodeSettings';

const hasParent = (node: HTMLElement, fn: (el: HTMLElement) => boolean): boolean => {
  return !!(fn(node) || (node.parentElement && hasParent(node.parentElement, fn)));
};

const isCodeTabsTabClick = (editor: Editor, event: React.MouseEvent) => {
  try {
    return (
      CodeTabs.is(ReactEditor.toSlateNode(editor, event.target as HTMLElement)) &&
      hasParent(event.target as HTMLElement, (el: HTMLElement) => el.dataset.component === CodeTabs.tabId)
    );
  } catch {
    return false;
  }
};

const CodeSettings = () => {
  const editor = useSlateStatic();
  const [{ path }, dispatch] = useCodeSettings();

  const node: CodeTabsElement | null = path ? (Node.get(editor, path) as CodeTabsElement) : null;
  const [target, setTarget] = useState<HTMLButtonElement | null>(null);
  const open = !!(target && node);

  useLayoutEffect(() => {
    if (!node) return;
    /*
     * Ideally, this would be more declarative, but since the code tabs aren't
     * actually elements in the editor state, we don't get the same
     * assurances and conveniences to look up their dom nodes from
     * `ReactEditor`. We'd instead have to manage the current active refs, and
     * I'm just really tired right now.
     */
    const tab = ReactEditor.toDOMNode(editor, node).querySelector(`[data-index="${node.active}"]`);

    setTarget(tab as HTMLButtonElement);
  }, [editor, node]);

  useEffect(() => {
    if (!node) setTarget(null);
  }, [node]);

  const close = useCallback(() => {
    dispatch({ type: MenuActionTypes.close });
  }, [dispatch]);

  useEventListener(
    'keydown',
    (event: KeyboardEvent) => open && event.key === 'Escape' && dispatch({ type: MenuActionTypes.close }),
  );

  const onChangeInput = useCallback(
    event => {
      if (!node) return;

      const { value } = event.target;

      CodeTabs.operations.updateCode(editor, { meta: value }, { at: path, index: node.active });
    },
    [editor, node, path],
  );

  const onKeyDownInput = useCallback(
    event => {
      if (event.key === 'Enter') close();
    },
    [close],
  );

  const onDeleteTab = useCallback(() => {
    if (!node) return;

    CodeTabs.operations.removeCodeTab(editor, path, node.active);

    close();
    ReactEditor.focus(editor);
  }, [close, editor, node, path]);

  const onClickLanguage = useCallback(
    event => {
      if (!node) return;

      CodeTabs.operations.setLang(editor, event.target.dataset.lang.toLowerCase(), { at: path, index: node.active });

      close();
    },
    [node, editor, path, close],
  );

  /*
   * We want to pass control of the actual open state to the `CodeTabs`
   * component if we're clicking on a `Tabs` component. The `CodeTabs`
   * components has it's own logic about the state of the menu in regards to
   * the active tab. :shrug:
   */
  const onMenuClose = useCallback(
    event => {
      if (!isCodeTabsTabClick(editor, event)) {
        close();
      }
    },
    [close, editor],
  );

  /* Using a unique key forces a re-render when we change tabs. The input component doesn't reset its value if the new value is an empty string */

  return (
    <MenuDropdown close={onMenuClose} open={open} target={{ current: target }}>
      <Menu className={classes.CodeSettings}>
        <MenuItem focusable={false}>
          <Input
            key={`${path ? [...path, node?.active].join(',') : ''}`}
            className={classes['CodeSettings-Input']}
            onChange={onChangeInput}
            onKeyDown={onKeyDownInput}
            placeholder="Custom Tab Name"
            size="sm"
            value={node?.tabs?.[node?.active]?.meta || ''}
          />
        </MenuItem>
        <div className={classes['CodeSettings-Languages']}>
          <MenuHeader>Languages</MenuHeader>
          {Object.entries(Code.languages).map(([lang, label], i) => {
            const active = lang === node?.tabs?.[node?.active]?.lang;

            return (
              <MenuItem
                key={i}
                active={active}
                className={classes['CodeSettings-MenuItem']}
                data-lang={lang}
                onClick={onClickLanguage}
              >
                {label}
              </MenuItem>
            );
          })}
        </div>
        <MenuItem className={classes['CodeSettings-Menu']} color="red" onClick={onDeleteTab}>
          Remove Tab
        </MenuItem>
      </Menu>
    </MenuDropdown>
  );
};

export default CodeSettings;

export { default as useCodeSettings } from './useCodeSettings';
