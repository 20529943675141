/* eslint-disable consistent-return */

import type { Link, Node as MdNode } from 'mdast';
import type { NodeEntry } from 'slate';

import { Editor, Node, Text, Transforms } from 'slate';

import { deserializer, getNode, getNodes } from '@ui/MarkdownEditor/editor/parser';
import { offsetToPoint } from '@ui/MarkdownEditor/editor/utils';
import { EmbedTypes } from '@ui/MarkdownEditor/enums';
import type { BaseMdNode, FormattedText, LinkElement, Normalizer } from '@ui/MarkdownEditor/types';

import { isMarkdownCharOf, create as mdCharCreate } from '../MarkdownChar/shared';

import { toString, type, isLink, defaultLink, LINK_ICON } from './shared';

const isMdLink = (node: MdNode) => 'type' in node && node.type === type;
const getLink = (mdast: BaseMdNode) => getNode(mdast, isMdLink);
const getLinks = (mdast: BaseMdNode) => getNodes(mdast, isMdLink);

const getLabelFromMdast = (link: Link, string: string) => {
  if (link.children.length === 0) return '';

  const firstChild = link.children[0];
  const lastChild = link.children[link.children.length - 1];

  return string.slice(firstChild?.position?.start?.offset, lastChild?.position?.end?.offset);
};

const parseNewLink: Normalizer = next => (editor, nodeEntry) => {
  const [node, path] = nodeEntry;
  if (!Text.isText(node) || Editor.above(editor, { at: path, match: n => isLink(n) })) return next();

  const string = Node.string(node);
  // @perf: we can short circuit the deserializer with a quick regex
  if (!string.match(/\[.*\]\(.*\)/)) return next();

  const mdast = deserializer(string);
  if (!mdast) return next();

  Array.from(getLinks(mdast))
    .reverse()
    .forEach(link => {
      const { position, url, title, children } = link;
      const label = getLabelFromMdast(link, string);

      const linkLocation = {
        anchor: offsetToPoint(nodeEntry, position.start.offset),
        focus: offsetToPoint(nodeEntry, position.end.offset),
      };

      Editor.withoutNormalizing(editor, () => {
        // @hack: In Link/apply.ts, we have a hack to prevent the selection
        // from staying at the edge of the link. So if we insert a link at the
        // end of a line, the hack wants to push the selection onto the next
        // point, which in this case would be the next line. Normally, the
        // slate will insert an empty text node before and after an inline, but
        // our apply hack happens before that normalization. A quick and dirty
        // fix is to insert that text node ourselves.
        // ADDENDUM: with whitespace so we don't enter the mirror realm
        if (title === '@embed' && editor.props?.useMDX) {
          Transforms.insertNodes(
            editor,
            [{ type: 'embed-block', title, typeOfEmbed: EmbedTypes.default, url, openMenu: true, children }],
            {
              at: path,
              select: true,
            },
          );
        } else {
          Transforms.insertNodes(editor, { text: ' ' }, { at: linkLocation.focus, select: true });
          Transforms.insertNodes(editor, defaultLink({ type, url, title, label }), { at: linkLocation });
        }
      });
    });
};

const expandBrokenLink: Normalizer =
  next =>
  (editor, [node, path]) => {
    if (!isLink(node)) return next();

    const string = Node.string(node);
    if (string.match(/^\[.*\]\(.*\)$/)) return next();

    const urlEntry = Editor.nodes(editor, { at: path, match: n => isMarkdownCharOf(n, LINK_ICON) }).next().value;

    Editor.withoutNormalizing(editor, () => {
      if (urlEntry) {
        Transforms.removeNodes(editor, { at: urlEntry[1] });
        Transforms.insertText(editor, node.url, { at: { path: urlEntry[1], offset: 0 } });
      }
      Transforms.unwrapNodes(editor, { at: path });
    });
  };

const syncUrlPortion = (editor: Editor, [node, path]: NodeEntry<LinkElement>) => {
  const leftParenPoint = offsetToPoint([node, path], `[${node.label}](`.length);
  const rightParenPoint = offsetToPoint([node, path], Node.string(node).length - 1, { affinity: 'forward' });

  // @note: I **wish** this could just be a single `insertNodes` operation, but
  // I think we'd have to do some splitting first. So instead, lets surgically
  // remove any text in the url area.
  if (node.url) {
    if (!Editor.isStart(editor, rightParenPoint, rightParenPoint.path)) {
      Transforms.delete(editor, { at: rightParenPoint, distance: rightParenPoint.offset, reverse: true });
    }

    if (!Editor.isEnd(editor, leftParenPoint, leftParenPoint.path)) {
      const leftParen = Node.get(editor, leftParenPoint.path) as FormattedText;
      const distance = leftParen.text.length - leftParenPoint.offset;
      Transforms.delete(editor, { at: leftParenPoint, distance });
    }
  } else {
    Transforms.insertNodes(editor, mdCharCreate(LINK_ICON), { at: { anchor: leftParenPoint, focus: rightParenPoint } });
  }
};

const syncAttributes: Normalizer =
  next =>
  (editor, [node, path]) => {
    if (!isLink(node)) return next();

    const string = toString(node);
    const mdast = deserializer(string, { settings: { position: true } });
    if (!mdast) return next();

    const link = getLink(mdast);
    if (!link) return next();

    const updates: Partial<LinkElement> = {};

    if (link.url !== node.url) {
      updates.url = link.url;
    }

    const label = getLabelFromMdast(link, string);
    if (label !== node.label) {
      updates.label = label;
    }

    if (Object.keys(updates).length === 0) {
      return next();
    }

    Editor.withoutNormalizing(editor, () => {
      Transforms.setNodes(editor, updates, { at: path });

      if (updates.url) {
        syncUrlPortion(editor, [node, path]);
      }
    });
  };

const normalizeNode = [parseNewLink, expandBrokenLink, syncAttributes];

export default normalizeNode;
