import React from 'react';

import Flex from '@ui/Flex';
import Input from '@ui/Input';
import InputGroup from '@ui/InputGroup';
import Select from '@ui/Select';

import menuClasses from '../../style.module.scss';
import classes from '../style.module.scss';

interface Props {
  label: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeUnit: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  sizeOptions: { label: string; value: number | string }[];
  unit: string;
  value: number | string;
}

const EmbedSizeInput = ({ label, value, onChange, unit, onChangeUnit, sizeOptions }: Props) => (
  <Flex className={menuClasses['EditorMenu-label']} gap="sm" layout="col" tag="label">
    <div>{label}</div>
    <InputGroup columnLayout="1fr auto" separators size="sm">
      <Input className={classes['Embed-Input']} onChange={onChange} size="sm" type="number" value={value} />
      <Select
        defaultValue={sizeOptions.find((opt: { label: string }) => opt.label === unit)?.value?.toString()}
        onChange={onChangeUnit}
        options={sizeOptions}
      />
    </InputGroup>
  </Flex>
);

export default EmbedSizeInput;
