import { Transforms } from 'slate';

import {
  Embed,
  EmbedBlock,
  Image,
  ImageBlock,
  Link,
  Heading,
  Html,
  Blockquote,
  List,
  CodeTabs,
  HorizontalRule,
  Recipe,
  ReusableContent,
  Table,
} from '../blocks';

const slashMenuConfig = {
  Blocks: {
    ...Link.slashMenu,
    ...Heading.slashMenu,
    ...Blockquote.slashMenu,
    ...List.slashMenu,
    ...CodeTabs.slashMenu,
    ...HorizontalRule.slashMenu,
    ...Image.slashMenu,
    ...ImageBlock.slashMenu,
    ...Recipe.slashMenu,
    ...Table.slashMenu,
    ...Html.slashMenu,
  },
  Embeds: {
    ...Embed.slashMenu,
    ...EmbedBlock.slashMenu,
  },
  Reusable: {
    ...ReusableContent.slashMenu,
  },
};

/*
 * @todo: There is a bug somewhere. Insert the following:
 *
 * This is a link [link](www.google.com) foo
 *
 * Then open the slash menu at the end of the line and insert a Heading 1. The
 * editor selection should be correct, but the actual cursor will be at the
 * beginning of the " foo" text node.
 */

const deleteSlashMenu = fn => editor => {
  const [{ rangeRef }] = editor.slashMenu;

  Transforms.select(editor, rangeRef.current);
  Transforms.delete(editor);

  fn(editor);
};

export const transforms = Object.entries(slashMenuConfig).reduce((acc, [, subItems]) => {
  Object.entries(subItems).forEach(([name, { transform }]) => {
    if (transform) {
      acc[name] = deleteSlashMenu(transform);
    }
  });

  return acc;
}, {});

const commands = Object.entries(slashMenuConfig).flatMap(([category, subItems]) =>
  Object.entries(subItems).map(([name, { transform, ...item }]) => {
    return { category, name, ...item };
  }),
);

export default commands;
