import type { RenderElementProps } from 'slate-react';

import isEqual from 'lodash/isEqual';
import React, { useEffect } from 'react';
import { Editor, Range, Text, Transforms } from 'slate';
import { ReactEditor, useSelected, useSlateStatic } from 'slate-react';

import useClassy from '@core/hooks/useClassy';

import type { ParagraphElement } from '@ui/MarkdownEditor/types';

import { isParagraphPlaceholderParent } from '../shared';
import { isTableCell } from '../TableCell/shared';

import classes from './style.module.scss';

interface Props extends RenderElementProps {
  element: ParagraphElement;
}

export const isValidPlaceholderParent = (editor: Editor, element: ParagraphElement) => {
  const path = ReactEditor.findPath(editor, element);
  const [parent] = Editor.parent(editor, path);

  return isParagraphPlaceholderParent(parent) && !Editor.above(editor, { match: isTableCell });
};

const isCollapsed = (editor: Editor) => editor.selection && Range.isCollapsed(editor.selection);

const elementIsEmpty = (element: ParagraphElement) =>
  element.children.length === 1 && Text.isText(element.children[0]) && element.children[0].text.length === 0;

const editorIsEmpty = (editor: Editor, element: ParagraphElement) =>
  editor.children.length === 1 && editor.children[0] === element && isEqual(element.children, [{ text: '' }]);

const Component = ({ attributes, children, element }: Props) => {
  const selected = useSelected();
  const editor = useSlateStatic();
  const bem = useClassy(classes, 'Paragraph');

  const shouldRenderPlaceholder =
    isValidPlaceholderParent(editor, element) &&
    ((selected && isCollapsed(editor) && elementIsEmpty(element)) || editorIsEmpty(editor, element));

  /*
   * Consider the following markdown:
   *
   * ```
   * Some text\n
   *
   * More text
   * ```
   *
   * The newline after 'Some text' will render in the html as a break, but it's
   * at the end of a paragraph, so it won't add any additional spacing. To make
   * this clear in the editor, I want to normalize it away. However, there's no
   * way to run normalizations on deselected nodes. We have to wait for it to
   * be deselected, for the normal case of typing some text, hitting
   * `shift+enter` and continuing to type.
   *
   * So this is a nasty workaround.
   */
  useEffect(() => {
    if (selected) return;

    const [leaf, path] = Editor.leaf(editor, ReactEditor.findPath(editor, element), { edge: 'end' });

    if (leaf.text.match(/\n$/)) {
      Transforms.delete(editor, { at: { path, offset: leaf.text.length }, reverse: true, unit: 'character' });
    }
  }, [editor, element, selected]);

  return (
    <p {...attributes} className={bem('&', shouldRenderPlaceholder && '_Placeholder')}>
      {children}
    </p>
  );
};

export default Component;
