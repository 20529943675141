import { insertLink } from './operations';

const slashMenu = {
  Link: {
    icon: 'link',
    basic: true,
    transform: insertLink,
  },
};

export default slashMenu;
