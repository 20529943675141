/* eslint-disable consistent-return */
import type { BasePoint, Operation } from 'slate';

import { Editor } from 'slate';

import { blocksByType } from '@ui/MarkdownEditor/editor/byType';
import type { ReadmeNode } from '@ui/MarkdownEditor/types';

import { Variable } from '../../blocks';

// @note: This is a bit of a hack to workaround standard text editing behavior.
// This applies to any node that has markdown delimiters, links, variables,
// etc. Assume you have a link and an empty text node next to it:
//
// [
//   {
//     "type": "paragraph",
//     "children": [
//       { "text": "" },
//       {
//         "type": "link",
//         "title": null,
//         "url": "http://readme.com",
//         "children": [{ "text": "[this is alink](🔗)" }],
//         "label": "this is alink"
//       },
//       { "text": "" },
//     ]
//   }
// ]
//
// If you click on the line anywhere after the link, the cursor will be placed
// at the end of the link node. In this case the selection would be at:
//
// { path: [0, 1, 0], offset: 18 }
//
// This is typically what you want if you have some formatted text. The way
// that we've designed our markdown editor, this is almost never what we want.

const hasInlineMd = (n: ReadmeNode) => 'type' in n && blocksByType[n.type]?.hasInlineMd;

const adjustPoint = (editor: Editor, point: BasePoint | undefined): BasePoint | void => {
  if (!point) return;

  const [, path] = Editor.above(editor, { at: point, match: hasInlineMd }) || [];
  if (!path) return;

  if (Editor.isStart(editor, point, path)) {
    return Editor.before(editor, point);
  } else if (Editor.isEnd(editor, point, path)) {
    return Editor.after(editor, point);
  }
};

const coerceSelection = (editor: Editor, op: Operation) => {
  if (op.type !== 'set_selection' || !op.newProperties) return op;

  const anchor = adjustPoint(editor, op.newProperties.anchor);
  const focus = adjustPoint(editor, op.newProperties.focus);

  if (anchor) op.newProperties.anchor = anchor;
  if (focus) op.newProperties.focus = focus;

  return op;
};

// @note: This is a bit of a hack to workaround standard text editing behavior.
// This applies to any node that has markdown delimiters, links, variables,
// etc. Assume you're inserting a variable:
//
// [
//   {
//     "type": "paragraph",
//     "children": [
//       { "text": "{user.name" },
//     ]
//   }
// ]
//
// As soon as you type '}', it will convert to the variable, and if the
// operation doesn't move the selection you could end up at the end of the
// variable. You don't want to be insert characters on the edges of the
// variable, ie:
//
//
// {
//   "type": "paragraph",
//   "children": [
//     { "text": "" },
//     {
//       "type": "variable",
//       "name": "name",
//       "children": [
//         { "text": "{user.email}a" }
//       ]
//     },
//     { "text": "" },
//   ]
// }
//
// So this hijacks the 'insert_text' operation to insert it, delete it, insert
// it in the next node and move the selection. Something changed about slates
// implementation, because we used to be able to only modify the original
// 'insert_text' operation, but that started causing a double insert!
const affinityHack = (editor: Editor, op: Operation) => {
  if (op.type !== 'insert_text') return op;

  const { path, offset } = op;
  const [node, inlineMdPath] = Editor.above(editor, { at: { path, offset }, match: hasInlineMd }) || [];
  if (!inlineMdPath) return op;

  let point;
  let affinity;
  if (Editor.isStart(editor, { path, offset }, inlineMdPath)) {
    affinity = 'start';
    point = Editor.before(editor, { path, offset });
  } else if (Editor.isEnd(editor, { path, offset }, inlineMdPath)) {
    affinity = 'end';
    point = Editor.after(editor, { path, offset });
  }

  if (!point || !node) {
    return op;
  }

  if (!(Variable.is(node) && editor.props.useMDX)) {
    return {
      ...op,
      ...point,
    };
  }

  const newPoint = {
    path: point.path,
    offset: affinity === 'end' ? op.text.length : point.offset - op.text.length,
  };

  const undo = {
    ...op,
    type: 'remove_text',
    text: 'or',
  };

  const reinsert = {
    ...op,
    path: point.path,
    offset: point.offset,
  };

  const select = {
    type: 'set_selection',
    oldProperties: editor.selection,
    newProperties: {
      anchor: newPoint,
      focus: newPoint,
    },
  };

  return [op, undo, reinsert, select];
};

export default [coerceSelection, affinityHack];
