import type { HTMLAttributes } from 'react';

import React, { useEffect, useMemo, useState } from 'react';
import { useSlateStatic, useSelected, ReactEditor } from 'slate-react';

import type { CMEditor } from '@ui/CodeSnippet';
import CodeSnippet from '@ui/CodeSnippet';
import { acrossBlocks } from '@ui/MarkdownEditor/editor/selection';
import type { CodeTabsElement, HtmlBlock } from '@ui/MarkdownEditor/types';
import useClassName from '@ui/MarkdownEditor/useClassName';

import onKeyDown from './onKeyDown';
import classes from './style.module.scss';

export type CodeEditorProps = HTMLAttributes<HTMLDivElement> & {
  className?: string;
  code: string;
  element: CodeTabsElement | HtmlBlock;
  id?: string;
  language: string;
  onChange: (value: string) => void;
};

const CodeEditor = ({ id, className, code = '', element, language, onChange }: CodeEditorProps) => {
  const editor = useSlateStatic();
  const [cmEditor, setCmEditor] = useState<CodeMirror.Editor | null>(null);
  const selected = useSelected();

  useEffect(() => {
    if (selected && !acrossBlocks(editor)) {
      cmEditor?.focus();
    }
  }, [selected, cmEditor, editor]);

  const options = useMemo(() => ({ editable: selected, foldGutter: true }), [selected]);
  const editorProps = useMemo(
    () => ({
      editorDidMount: setCmEditor,
      onChange: (_e: Event, _data: unknown, value: string) => onChange(value),
      onKeyDown: (codeMirrorEditor: CMEditor, event: KeyboardEvent) => {
        const path = ReactEditor.findPath(editor, element);
        onKeyDown(event, codeMirrorEditor, editor, path);
      },
    }),
    [onChange, editor, element],
  );

  const _className = useClassName(classes.CodeEditor, className);

  return (
    <CodeSnippet
      className={_className}
      code={code}
      contentEditable={false}
      // We're removing editor control here via contentEditable above so CodeMirror can
      // handle editing, so we have to pass our onChange and onKeyDown handlers along to CodeMirror
      editorProps={editorProps}
      id={id}
      language={language}
      options={options}
    />
  );
};

export default CodeEditor;
