import type { RenderElementProps } from 'slate-react';

import React from 'react';

import type { JsxCommentElement, JsxCommentInline } from '@ui/MarkdownEditor/types';

import classes from './style.module.scss';

interface Props extends RenderElementProps {
  element: JsxCommentElement | JsxCommentInline;
}

const JsxComment = ({ attributes, children, element }: Props) => {
  const Tag = 'isInline' in element && element.isInline ? 'span' : 'div';

  return (
    <Tag className={classes.JsxComment} {...attributes}>
      {children}
    </Tag>
  );
};

export default JsxComment;
