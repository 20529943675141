import { Node, Path, Text, Transforms } from 'slate';

import type { Normalizer } from '@ui/MarkdownEditor/types';

import { wrapCommentToken } from './operations';
import { isJsxCommentToken } from './shared';

const regexps = {
  start: /{\s*\/\*/,
  end: /\*\/\s*}/,
};

const wrap: Normalizer =
  next =>
  (editor, [node, path]) => {
    if (!editor.props.useMDX) return next();
    if (!(Text.isText(node) && !isJsxCommentToken(Node.get(editor, Path.parent(path))))) return next();

    const found = ['start', 'end'].find(edge => {
      const match = node.text.match(regexps[edge]);
      if (!(match && typeof match.index !== 'undefined')) return false;

      return wrapCommentToken(editor, {
        at: {
          anchor: { path, offset: match.index },
          focus: { path, offset: match.index + match[0].length },
        },
        edge,
      });
    });

    return found ? undefined : next();
  };

const unwrap: Normalizer =
  next =>
  // eslint-disable-next-line consistent-return
  (editor, [node, path]) => {
    if (!isJsxCommentToken(node)) return next();

    const str = Node.string(node);
    if (regexps[node.edge].test(str)) return next();

    Transforms.unwrapNodes(editor, { at: path, match: isJsxCommentToken });
  };

export default [wrap, unwrap];
