import type { $TSFixMe } from '@readme/iso';
import type { MdxJsxFlowElement } from 'mdast-util-mdx';

import type { JsxCommentElement, Serializer } from '@ui/MarkdownEditor/types';

import { type as tokenType } from '../JsxCommentToken/shared';

import { type } from './shared';

export const deserialize: $TSFixMe = (node, _, context) => {
  if (
    !(
      typeof node?.data?.estree === 'object' &&
      node?.data?.estree !== null &&
      'comments' in node.data.estree &&
      Array.isArray(node.data.estree.comments) &&
      node.data.estree.comments.length > 0
    )
  ) {
    return { type: 'paragraph', children: context.renderingLibrary.mdx(node).children[0] };
  }

  const [comment] = node.data.estree.comments;

  const content = context.deserialize(comment.value.replace(/^\/\*/, '').replace(/\*\/$/, ''));

  if (node.type === 'mdxTextExpression') {
    return {
      type,
      isInline: true,
      children: [
        { type: tokenType, edge: 'start', children: [{ text: '{/*' }] },
        ...content[0].children,
        { type: tokenType, edge: 'end', children: [{ text: '*/}' }] },
      ],
    };
  }

  const children = [
    {
      type: 'paragraph',
      children: [{ text: '' }, { type: tokenType, edge: 'start', children: [{ text: '{/*' }] }, { text: '' }],
    },
    ...content,
    {
      type: 'paragraph',
      children: [{ text: '' }, { type: tokenType, edge: 'end', children: [{ text: '*/}' }] }, { text: '' }],
    },
  ];

  return { type, children };
};

export const serialize: Serializer<JsxCommentElement, MdxJsxFlowElement> = (node, deeper, { renderingLibrary }) => {
  const subtree = deeper(node);

  if ('mdx' in renderingLibrary) {
    const string = renderingLibrary
      .mdx({ type: 'root', children: subtree })
      .replace(/^\s*\\{\/\\\*/, '{/*')
      .replace(/\\\*\/}\s*$/, '*/}');

    return renderingLibrary.mdast(string).children;
  }

  return subtree;
};
