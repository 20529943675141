import type { $TSFixMe } from '@readme/iso';
import type { Text } from 'mdast';

import type { JsxCommentTokenElement, Serializer } from '@ui/MarkdownEditor/types';

import { type } from './shared';

export const deserialize: $TSFixMe = (node, _, context) => {
  if (
    !(
      typeof node?.data?.estree === 'object' &&
      node?.data?.estree !== null &&
      'comments' in node.data.estree &&
      Array.isArray(node.data.estree.comments) &&
      node.data.estree.comments.length > 0
    )
  ) {
    return { type: 'paragraph', children: context.renderingLibrary.mdx(node).children[0] };
  }

  const [comment] = node.data.estree.comments;
  const content = context.deserialize(comment.value.slice(2, comment.value.length - 1));

  const children = [
    {
      type: 'paragraph',
      children: [{ text: '' }, { type: 'jsx-comment-token', start: true, children: [{ text: '{/*' }] }, { text: '' }],
    },
    ...content,
    {
      type: 'paragraph',
      children: [{ text: '' }, { type: 'jsx-comment-token', end: true, children: [{ text: '*/}' }] }, { text: '' }],
    },
  ];

  return { type, children };
};

export const serialize: Serializer<JsxCommentTokenElement, Text> = node => {
  return { type: 'text', value: node.edge === 'start' ? '{/*' : '*/}' };
};
