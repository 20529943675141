import { format } from 'date-fns';
import React, { memo, useEffect, useCallback, useRef, useMemo } from 'react';
import { FixedSizeList as VirtualList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { Editor, type BaseRange, Transforms } from 'slate';
import { useSlateStatic, ReactEditor } from 'slate-react';

import useClassy from '@core/hooks/useClassy';

import Flex from '@ui/Flex';
import { ReusableContent, GlossaryTerm, MenuHandle } from '@ui/MarkdownEditor/editor/blocks';
import MenuDropdown from '@ui/MarkdownEditor/editor/MenuDropdown';
import { MenuActionTypes, MenuConfigActionTypes } from '@ui/MarkdownEditor/enums';
import Menu, { MenuItem } from '@ui/Menu';
import Spinner from '@ui/Spinner';

import classes from './style.module.scss';
import useReusableContentMenu from './useReusableContentMenu';

// Height dimension of each item in the list
const ITEM_SIZE = 48;

// Number of rows remaining before triggering data loading on scroll
const ROW_THRESHOLD = 5;

const ReusableContentMenu = () => {
  const editor = useSlateStatic();
  const [
    { selected, filtered, reusableContent, target, open, rangeRef, hasNextPage, isNextPageLoading, shortcut },
    dispatch,
  ] = useReusableContentMenu();

  const selectedItem = filtered[selected];
  const selectedTagOrName = selectedItem ? ('tag' in selectedItem ? selectedItem.tag : selectedItem.term) : null;
  const results = filtered;
  const menuRef = useRef<HTMLDivElement>(null);

  const bem = useClassy(classes, 'ReusableContentMenu');

  const onClick = (tag: string) => {
    ReactEditor.focus(editor);

    if (rangeRef) {
      ReusableContent.operations.insertReusableContent(editor, tag, { at: rangeRef?.current as BaseRange });
    }

    dispatch({ type: MenuActionTypes.close });
  };

  const onClickGlossary = (name: string) => {
    if (!rangeRef?.current) return;

    GlossaryTerm.operations.insertGlossaryTerm(editor, name, { at: rangeRef?.current as BaseRange });

    const entry = Editor.above(editor, { at: rangeRef.current as BaseRange, match: MenuHandle.is });
    if (entry) Transforms.unwrapNodes(editor, { at: entry[1] });

    dispatch({ type: MenuActionTypes.close });

    ReactEditor.focus(editor);
  };

  useEffect(() => {
    if (!open || !menuRef?.current) return;

    const menuItem = [0, 0, selected].reduce((node: Element, index) => node?.children[index], menuRef.current);

    if (!menuItem) return;
    if (!menuItem.scrollIntoView) return;

    menuItem.scrollIntoView({ behavior: 'smooth', block: 'end' });
  }, [open, selected]);

  const isItemLoaded = useCallback(
    (index: number) => !hasNextPage || index < results.length,
    [hasNextPage, results.length],
  );

  const itemCount = hasNextPage ? results.length + 1 : results.length;
  const loadMoreItems = isNextPageLoading ? () => {} : () => dispatch({ type: MenuConfigActionTypes.loadNextPage });

  const listHeight = useMemo(() => {
    if (!results?.length) return 0;
    const maxItemsInView = 5;
    return results.length >= maxItemsInView + 1
      ? ITEM_SIZE * maxItemsInView + ITEM_SIZE / 2 // Add half an item to the height to allow for scrolling affordance
      : ITEM_SIZE * results.length;
  }, [results]);

  if (editor.props.disallowReusableContent || !editor.props.useReusableContent) return null;

  return results.length ? (
    <MenuDropdown className={bem('&')} open={open} target={target}>
      <Menu ref={menuRef} className={bem('-menu')} data-testid="reusable-content-menu" role="menu">
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={itemCount}
          loadMoreItems={loadMoreItems}
          threshold={ROW_THRESHOLD}
        >
          {({ onItemsRendered, ref }) => (
            <VirtualList
              ref={ref}
              height={listHeight}
              itemCount={itemCount}
              itemData={results}
              itemSize={ITEM_SIZE}
              onItemsRendered={onItemsRendered}
              width="100%"
            >
              {({ index, style }) => {
                const block = results[index];
                if (!block) {
                  return (
                    <MenuItem key="loading" className={bem('-menu-results', 'loading')} disabled style={style}>
                      <Spinner />
                    </MenuItem>
                  );
                }

                const isRC = 'tag' in block;
                const key = isRC ? block.tag : block.term;

                return (
                  <MenuItem
                    key={key}
                    aria-current={key === selectedTagOrName}
                    className={bem('-menu-results', key === selectedTagOrName && 'selected')}
                    description={
                      'updated_at' in block
                        ? `Last Updated: ${format(new Date(block.updated_at), 'MM/dd/yyyy')}`
                        : 'Glossary Term'
                    }
                    onClick={() => {
                      if (isRC) {
                        onClick(block.tag);
                      } else {
                        onClickGlossary(block.term);
                      }
                    }}
                    style={style}
                  >
                    <span className={bem('-menu-results-name')}>{isRC ? block.name : block.term}</span>
                  </MenuItem>
                );
              }}
            </VirtualList>
          )}
        </InfiniteLoader>
      </Menu>
    </MenuDropdown>
  ) : !shortcut && !reusableContent.length ? (
    <MenuDropdown className={bem('&')} open={open} target={target}>
      <Menu ref={menuRef} className={bem('-menu')} data-testid="reusable-content-menu" role="menu">
        <Flex align="center" className={bem('-menu-results_empty')} justify="center">
          No Reusable Content
        </Flex>
      </Menu>
    </MenuDropdown>
  ) : null;
};

export default memo(ReusableContentMenu);
