import isEqual from 'lodash/isEqual';
import { Editor, Path, Node, Range, Transforms } from 'slate';

import emptyNode from '@ui/MarkdownEditor/emptyNode';
import { ImageMenuActionTypes } from '@ui/MarkdownEditor/enums';
import type { OnKeyDown } from '@ui/MarkdownEditor/types';

import { isImageBlock } from './shared';

const enter: OnKeyDown = (event, editor) => {
  if (!(event.key === 'Enter' && !event.shiftKey && editor.selection)) return;

  const imageEntry = Editor.above(editor, { match: isImageBlock });

  if (!imageEntry) return;
  const [image, path] = imageEntry;

  if (
    !(
      Range.isCollapsed(editor.selection) &&
      Editor.isEnd(editor, editor.selection.anchor, path) &&
      isEqual(emptyNode(), image.children[image.children.length - 1])
    )
  )
    return;

  event.preventDefault();
  event.stopPropagation();

  const lastLineOrCaptionPath = image.children.length === 1 ? path : [...path, image.children.length - 1];
  const nextSiblingPath = Path.next(Path.parent(path));
  Editor.withoutNormalizing(editor, () => {
    Transforms.removeNodes(editor, { at: lastLineOrCaptionPath });
    Transforms.insertNodes(editor, [emptyNode()], { at: nextSiblingPath, select: true });
  });
};

const arrowDown: OnKeyDown = (event, editor) => {
  if (!(event.key === 'ArrowDown' && !event.shiftKey && editor.selection)) return;

  const imageEntry = Editor.above(editor, { match: isImageBlock });
  if (!imageEntry) return;

  const [, imagePath] = imageEntry;

  const nextPath = imagePath && Path.next(imagePath);
  if (nextPath && Node.has(editor, nextPath)) return;

  // If we are not at the end of the caption, do nothing
  if (!isEqual(editor.selection.anchor.path, Editor.end(editor, imagePath).path)) return;

  event.preventDefault();
  event.stopPropagation();

  // If there's no next node, insert an empty one on arrow down!
  Transforms.insertNodes(editor, emptyNode(), { at: nextPath, select: true });
};

const arrowUp: OnKeyDown = (event, editor) => {
  if (!(event.key === 'ArrowUp' && !event.shiftKey && editor.selection)) return;

  const imageEntry = Editor.above(editor, { match: isImageBlock });

  if (!imageEntry) return;
  const [, path] = imageEntry;

  // If we are not at the beginning of the caption, do nothing
  if (!isEqual(editor.selection.anchor.path, Editor.start(editor, path).path)) return;

  event.preventDefault();
  event.stopPropagation();

  Transforms.select(editor, Path.previous(path));
};

const backspace: OnKeyDown = (event, editor) => {
  if (!(event.key === 'Backspace' && editor.selection)) return;

  const imageEntry = Editor.above(editor, { match: isImageBlock });
  if (!imageEntry) return;

  const [caption, imagePath] = imageEntry;

  const beginningOfCaption = isEqual(editor.selection.anchor, Editor.start(editor, imagePath));
  if (Node.string(caption) !== '') {
    if (beginningOfCaption) {
      // If we are at the beginning of the caption, do nothing
      event.preventDefault();
      event.stopPropagation();
    }
    return;
  }

  // if the caption is empty, remove image node and either select
  // previous node or insert an empty one
  event.preventDefault();
  event.stopPropagation();

  const previousEntry = Editor.previous(editor, { at: imagePath });

  Transforms.removeNodes(editor, { at: imagePath });
  const [, dispatch] = editor.imageMenu;
  dispatch({ type: ImageMenuActionTypes.close });

  if (previousEntry) {
    Transforms.select(editor, Editor.start(editor, previousEntry[1]));
  } else {
    Transforms.insertNodes(editor, emptyNode(), { at: imagePath, select: true });
  }
};

export default [arrowDown, arrowUp, backspace, enter];
