import type { Node } from 'mdast';

import type { FigcaptionElement, FigcaptionMdNode, ParagraphElement, ReadmeElement } from '@ui/MarkdownEditor/types';

import { type } from './shared';

export const deserialize = (
  node: FigcaptionMdNode,
  deeper: (node: FigcaptionMdNode) => [ParagraphElement],
): FigcaptionElement => {
  return {
    type,
    children: deeper(node),
  };
};

export const serialize = (node: FigcaptionElement, deeper: (node: ReadmeElement) => Node[]): FigcaptionMdNode => {
  return {
    type: 'figcaption',
    data: {
      hName: 'figcaption',
    },
    children: deeper(node) as FigcaptionMdNode['children'],
  };
};
