import React from 'react';

import useClassy from '@core/hooks/useClassy';

import Flex from '@ui/Flex';
import Graphic from '@ui/Graphic';

import classes from './index.module.scss';

interface ErrorStateProps {
  className?: string;
  error?: Error | null;
  message?: string | null;
  title?: string | null;
}

function ErrorState({ className, error, title, message }: ErrorStateProps) {
  const bem = useClassy(classes, 'ErrorState');

  const displayTitle = title || 'Uh oh, something went wrong!';
  const displayMessage = message || (
    <>
      Try refreshing your browser
      {error ? (
        <>
          {' '}
          or contact{' '}
          <a
            className={bem('-link')}
            href={`mailto:support@readme.io?subject=Docs Editor Error ${error?.message ?? ''}`}
          >
            support@readme.io
          </a>{' '}
          for help.
        </>
      ) : (
        '.'
      )}
    </>
  );

  return (
    <Flex
      align="center"
      className={bem('&', className)}
      data-testid="error-state"
      gap={0}
      justify="center"
      layout="col"
    >
      <Graphic name="warning" size={25} wrapperClassName={bem('-graphic')} />
      {title !== null && <h3 className={bem('-title')}>{displayTitle}</h3>}
      {message !== null && <p className={bem('-message')}>{displayMessage}</p>}
    </Flex>
  );
}

export default ErrorState;
