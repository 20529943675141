import type { Element } from 'slate';

import React from 'react';
import { Editor, Range } from 'slate';
import { ReactEditor, useSelected, useSlateStatic } from 'slate-react';

import useClassy from '@core/hooks/useClassy';

import { CodeTabs, Html, Blockquote, Image } from '@ui/MarkdownEditor/editor/blocks';
import useSelectedClassName from '@ui/MarkdownEditor/editor/useSelectedClassName';
import { contains } from '@ui/MarkdownEditor/editor/utils';
import classes from '@ui/MarkdownEditor/style.module.scss';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  blockType?: string;
  children: React.ReactNode;
  element: Element;
  forwardedRef?: React.MutableRefObject<HTMLDivElement>;
};

const SelectionWrapper = ({ blockType, children, element, forwardedRef, style, ...attributes }: Props) => {
  const editor = useSlateStatic();
  const path = element ? ReactEditor.findPath(editor, element) : null;
  const range = path && Editor.range(editor, path);

  // checks if the selection is a code, html, or callout block that is being fully selected
  const isSpecialBlock = [CodeTabs.isCodeTabs, Html.isHtml, Blockquote.isCallout].find(fn => fn(element));
  const selected =
    useSelected() &&
    editor.selection &&
    contains(editor.selection, range) &&
    (isSpecialBlock ? Range.isExpanded(editor.selection) : true);
  const inline = editor.isInline(element);
  const svg = Image.isImage(element) && element.url?.match(/.svg$/);

  const bem = useClassy(classes, 'SelectionWrapper');
  const wrapperClassName = bem(
    '&',
    selected && '_selected',
    `-${element.type}`,
    blockType && `-${blockType}`,
    svg && '-svg',
    inline && '-inline',
    useSelectedClassName(),
  );
  const overlayClassName = bem(selected && '-overlay');

  const Tag = inline ? 'span' : 'div';

  return (
    <Tag ref={forwardedRef} className={wrapperClassName} role="none" {...attributes} style={style}>
      <Tag className={overlayClassName} id="overlay" />
      {children}
    </Tag>
  );
};

export default React.forwardRef<HTMLDivElement, Props>((props, ref) => (
  <SelectionWrapper forwardedRef={ref as React.MutableRefObject<HTMLDivElement>} {...props} />
));
