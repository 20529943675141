import { Editor, Node, Path, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

import type { CMEditor } from '@ui/CodeSnippet';
import { isCodeTabs } from '@ui/MarkdownEditor/editor/blocks/CodeTabs/shared';
import { isHtml } from '@ui/MarkdownEditor/editor/blocks/Html/shared';
import type { ReadmeEditor } from '@ui/MarkdownEditor/types';

type Event = (event: KeyboardEvent, editor: ReadmeEditor, path: Path) => void;
type CmEvent = (event: KeyboardEvent, codeMirrorEditor: CMEditor, editor: ReadmeEditor, path: Path) => void;
type Range = CMEditor['doc']['sel']['ranges'][0];

const isCollapsed = ({ anchor, head }: Range) => anchor.line === head.line && anchor.ch === head.ch;
const isAtStart = (range: Range) => range.anchor.line === 0 && range.anchor.ch === 0;

const parent = (editor: ReadmeEditor, path: Path): Path => {
  const entry = Editor.above(editor, { at: path, match: node => isHtml(node) || isCodeTabs(node) });

  return entry ? entry[1] : path;
};

const moveSelectionUp: Event = (event, editor, path) => {
  event.preventDefault();
  event.stopPropagation();

  const parentPath = parent(editor, path);

  if (!Path.hasPrevious(parentPath)) return;

  const previousPath = Path.previous(parentPath);
  ReactEditor.focus(editor);
  // Set the path to the node above the code tabs element, then set the offset
  // to the end of the line. This avoids erroneously trying to select the second
  // tab in a code tab element, for example!
  Transforms.select(editor, Editor.end(editor, previousPath));
};

const backspaceOut: CmEvent = (event, codeMirrorEditor, editor, path) => {
  const { sel } = codeMirrorEditor.doc;
  if (!sel?.ranges?.[0]) return;
  const [range] = sel.ranges;

  // If we're at the beginning of the first line, backspace pops us out to the node above the code tabs node
  if (!isAtStart(range) || !isCollapsed(range)) return;

  moveSelectionUp(event, editor, path);
};

const upArrow: CmEvent = (event, codeMirrorEditor, editor, path) => {
  // Make sure we're at the top of the code block
  const currentLine = codeMirrorEditor.doc.sel?.ranges[0]?.anchor?.line;
  if (currentLine !== 0) return;

  moveSelectionUp(event, editor, path);
};

const leftArrow = backspaceOut;

const moveSelectionDown: Event = (event, editor, path) => {
  event.preventDefault();
  event.stopPropagation();

  const parentPath = parent(editor, path);
  const nextPath = Path.next(parentPath);
  if (Node.has(editor, nextPath)) {
    ReactEditor.focus(editor);
    Transforms.select(editor, Editor.start(editor, nextPath));
  }
};

const downArrow: CmEvent = (event, codeMirrorEditor, editor, path) => {
  // Make sure we're at the bottom of the code block
  const currentLine = codeMirrorEditor.doc.sel?.ranges[0]?.anchor?.line;
  const lastLine = codeMirrorEditor.doc.size - 1;
  if (currentLine !== lastLine) return;

  moveSelectionDown(event, editor, path);
};

const rightArrow: CmEvent = (event, codeMirrorEditor, editor, path) => {
  // Make sure we're at the top left corner of the code block
  const { line, ch } = codeMirrorEditor.doc.sel?.ranges?.[0]?.anchor || {};
  const lastLine = codeMirrorEditor.doc.size - 1;
  const endOfLastLine = codeMirrorEditor.doc.children[0]?.lines[lastLine]?.text?.length;
  if (!(line === lastLine && ch === endOfLastLine)) return;

  moveSelectionDown(event, editor, path);
};

const onKeyDown: CmEvent = (event, ...args) => {
  try {
    if (event.key === 'Backspace') backspaceOut(event, ...args);
    if (event.key === 'ArrowUp' && !event.shiftKey) upArrow(event, ...args);
    if (event.key === 'ArrowLeft' && !event.shiftKey) leftArrow(event, ...args);
    if (event.key === 'ArrowDown' && !event.shiftKey) downArrow(event, ...args);
    if (event.key === 'ArrowRight' && !event.shiftKey) rightArrow(event, ...args);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn((e as Error).message);
  }
};

export default onKeyDown;
