import { mdx } from '@readme/mdx';

import emptyNode from '@ui/MarkdownEditor/emptyNode';

import { type as reusableContentType } from '../ReusableContent/shared';

export const deserialize = (node, _, { reusableContent }) => {
  if (node.name === 'br') {
    return emptyNode();
  }

  if (node.name in reusableContent.tags) {
    return {
      type: reusableContentType,
      tag: node.name,
      children: [{ text: '' }],
      source: reusableContent.tags[node.name].source,
    };
  }

  const text = mdx(node).trim();

  return { type: 'paragraph', children: [{ text }] };
};
