export const deserialize = ({ editor, position, data, ...node }) => {
  return {
    ...node,
    children: [{ text: '' }],
    align:
      data?.hProperties?.align ||
      (data?.hProperties?.className === 'border' || data?.hProperties?.width ? 'center' : ''),
    width: data?.hProperties?.width,
    border: data?.hProperties?.className === 'border',
  };
};

export const serialize = ({ children, align, border = false, isInline, width, url, src, ...image }) => {
  if (isInline) {
    return {
      src: src || url,
      url: src || url,
      ...image,
    };
  }

  image.data = {
    hProperties: {
      ...(align && { align }),
      ...(border && { className: 'border' }),
      ...(width && { width }),
    },
  };

  return {
    align,
    width,
    src: src || url,
    url: src || url,
    ...image,
  };
};
