import type { RefObject } from 'react';
import type { Ancestor, NodeEntry } from 'slate';

import { Node, Text, Editor, Transforms, Path } from 'slate';

import type { ImageBlock, ImageElement } from '@ui/MarkdownEditor/types';

import { isOnlyBlockquote } from './Blockquote/shared';
import { isOnlyListItem } from './ListItem/shared';
import Paragraph from './Paragraph';

/*
 * @note: This file is a workaround for me not understanding
 * how to structure code so it doesn't create circular dependencies.
 */

export const isFirstTextOfParagraph = (editor: Editor, [node, path]: NodeEntry) => {
  if (!Text.isText(node)) return false;
  if (!Paragraph.isParagraph(Node.parent(editor, path))) return false;

  return path[path.length - 1] === 0;
};

export const isParagraphPlaceholderParent = (parent: Ancestor) =>
  [isOnlyBlockquote, isOnlyListItem, Editor.isEditor].find(isBlock => isBlock(parent));

export const selectAfter = (editor: Editor) => {
  if (!editor.selection) return;

  const after = Editor.after(editor, editor.selection);

  if (!after) {
    Transforms.insertNodes(
      editor,
      { text: '' },
      { at: Path.next(Path.parent(editor.selection.anchor.path)), select: true },
    );
  } else {
    Transforms.select(editor, after);
  }
};

export const FileInputCache = new WeakMap<ImageBlock | ImageElement, RefObject<HTMLInputElement>>();
