import type { Props } from './types';

import React from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { DragAndDropProvider } from '@core/hooks/useDragAndDropContext';
import { IsMouseDownProvider } from '@core/hooks/useIsMouseDown';

import {
  EmojiMenuProvider,
  PageMenuProvider,
  ReusableContentMenuProvider,
  ReusableContentModalProvider,
  SlashMenuProvider,
  VariableMenuProvider,
} from './editor';
import { ImageMenuProvider } from './editor/ImageMenu';
import RenderingLibraryProvider from './editor/RenderingLibraryProvider';
import { UrlMenuProvider } from './editor/UrlMenu/useUrlMenu';

const Contexts = ({
  basic = false,
  children,
  disallowHtmlBlocks,
  disallowRecipes,
  disallowReusableContent,
  glossaryTerms = [],
  subdomain = '',
  useAPIv2 = false,
  useMDX = false,
  useReusableContent = false,
  useTestData = false,
  variableDefaults = [],
  version = '',
}: Props) => {
  return (
    <ReusableContentMenuProvider
      basic={basic}
      enabled={!!useReusableContent && !disallowReusableContent}
      glossaryTerms={glossaryTerms}
      subdomain={subdomain}
      useMDX={useMDX}
      useTestData={useTestData}
      version={version}
    >
      <ReusableContentModalProvider enabled={!!useReusableContent && !disallowReusableContent}>
        <IsMouseDownProvider>
          <DragAndDropProvider backend={HTML5Backend}>
            <PageMenuProvider
              basic={basic}
              subdomain={subdomain}
              useAPIv2={useAPIv2}
              useTestData={useTestData}
              version={version}
            >
              <SlashMenuProvider
                basic={basic}
                disallowHtmlBlocks={disallowHtmlBlocks}
                disallowRecipes={disallowRecipes}
                useMDX={useMDX}
                useReusableContent={useReusableContent}
              >
                <VariableMenuProvider
                  glossaryTerms={glossaryTerms}
                  useMDX={useMDX}
                  useTestData={useTestData}
                  variableDefaults={variableDefaults}
                >
                  <EmojiMenuProvider>
                    <UrlMenuProvider>
                      <ImageMenuProvider>
                        <RenderingLibraryProvider useMDX={useMDX}>{children}</RenderingLibraryProvider>
                      </ImageMenuProvider>
                    </UrlMenuProvider>
                  </EmojiMenuProvider>
                </VariableMenuProvider>
              </SlashMenuProvider>
            </PageMenuProvider>
          </DragAndDropProvider>
        </IsMouseDownProvider>
      </ReusableContentModalProvider>
    </ReusableContentMenuProvider>
  );
};

export default React.memo(Contexts);
