import isEqual from 'lodash/isEqual';
import { Editor, Path, Transforms } from 'slate';

import emptyNode from '@ui/MarkdownEditor/emptyNode';

export const insertHorizontalRule = (editor, { at = editor.selection } = {}) => {
  const [node, path] = Editor.above(editor, { at, match: n => Editor.isBlock(editor, n) }) || [];

  const isEmpty = isEqual(emptyNode(), node);
  // If the node is empty, insert it here: otherwise, insert on the next line
  const dividerAt = isEmpty ? path : Path.next(path);
  Transforms.insertNodes(editor, emptyNode({ type: 'hr' }), { at: dividerAt });
  if (!isEmpty) {
    Transforms.insertNodes(editor, [emptyNode()], { at: Path.next(dividerAt), select: true });
  }
};

export default { insertHorizontalRule };
