import type { ReadType } from '@readme/api/src/mappings/customblock/types';

import React, { createContext, useContext, useEffect, useRef, useState } from 'react';

// eslint-disable-next-line no-restricted-imports
import type { CustomBlockType } from '@routes/Dash/CustomBlocks/types';

import type Modal from '@ui/Modal';

import ReusableContentModal from '.';

interface ReusableContentModalState {
  currentItem?: CustomBlockType | null;
  isOpen: boolean;
  onDeleteCallback: (() => void) | null;
  onSaveCallback: ((data?: ReadType) => void) | null;
}

interface ModalOpenProps {
  item?: CustomBlockType | null;
  onDelete?: () => void;
  onSave?: (data?: ReadType) => void;
}

type ReusableContentModalContextProps = ReusableContentModalState & {
  close: () => void;
  modalRef: React.RefObject<Modal> | null;
  open: ({ item, onSave }: ModalOpenProps) => void;
};

const ReusableContentModalContext = createContext<ReusableContentModalContextProps>({
  close: () => {},
  isOpen: false,
  currentItem: null,
  modalRef: null,
  onSaveCallback: () => {},
  onDeleteCallback: () => {},
  open: () => {},
});

export const ReusableContentModalProvider = ({
  enabled,
  children,
}: {
  children: React.ReactNode;
  enabled: boolean;
}) => {
  const modalRef = useRef<Modal>(null);

  const [state, setState] = useState<ReusableContentModalState>({
    isOpen: false,
    currentItem: null,
    onSaveCallback: null,
    onDeleteCallback: null,
  });

  useEffect(() => {
    modalRef.current?.toggle(state.isOpen);
  }, [state.isOpen]);

  const value = {
    ...state,
    open: ({ item, onSave, onDelete }: ModalOpenProps) => {
      setState({
        isOpen: true,
        currentItem: item,
        onSaveCallback: (update?: ReadType) => {
          onSave?.(update);
        },
        onDeleteCallback: onDelete || null,
      });
    },
    close: () => {
      setState({ isOpen: false, currentItem: null, onSaveCallback: null, onDeleteCallback: null });
    },
    modalRef,
  };

  return enabled ? (
    <ReusableContentModalContext.Provider value={value}>
      {children}
      <ReusableContentModal />
    </ReusableContentModalContext.Provider>
  ) : (
    <>{children}</>
  );
};

export const useReusableContentModal = () => useContext(ReusableContentModalContext);
export default useReusableContentModal;
