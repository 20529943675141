import type { useEmbedState } from './useEmbedState';

import { useCallback } from 'react';
import { Transforms } from 'slate';
import { useSlateStatic, ReactEditor } from 'slate-react';

import makeFetch from '@core/utils/makeFetch';

import type { EmbedBlock, EmbedElement } from '@ui/MarkdownEditor/types';

export const useEmbedHandlers = (element: EmbedBlock | EmbedElement, state: ReturnType<typeof useEmbedState>) => {
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element);
  const { type, urlEntered, setUrlValidationMessage, heightEntered, heightUnit, widthEntered, widthUnit } = state;

  const insertEmbed = useCallback(
    async ({ urlToUse, inputHeight, inputWidth, inputHeightUnit, inputWidthUnit }) => {
      try {
        new URL(urlToUse); // eslint-disable-line no-new
        setUrlValidationMessage('');

        if (element.iframe || type === 'iframe') {
          const isCodepen = new URL(urlToUse).host === 'codepen.io';
          const iFrameToEmbed = {
            html: false,
            url: isCodepen ? urlToUse.replace('/pen/', '/embed/') : urlToUse,
            href: urlToUse,
            typeOfEmbed: 'iframe',
            height: `${inputHeight || heightEntered}${inputHeightUnit || heightUnit}`,
            width: `${inputWidth || widthEntered}${inputWidthUnit || widthUnit}`,
            iframe: true,
            title: 'iframe',
          };

          if (editor.props.useMDX) {
            Transforms.setNodes(editor, { ...iFrameToEmbed }, { at: path });
          } else {
            Transforms.setNodes(
              editor,
              {
                ...iFrameToEmbed,
                data: { hName: 'embed', hProperties: iFrameToEmbed },
              },
              { at: path },
            );
          }

          return;
        }

        makeFetch(`/embedlookup?url=${urlToUse}&type=${type}`, {
          method: 'GET',
        })
          .then(res => res.json())
          .then(json => {
            const update = {
              html: json.gistHtml || json.data.media.html,
              url: json.data.url,
              title: json.data.title,
              favicon: json.data.favicon_url,
              image: json.data.images[0]?.url,
              typeOfEmbed: type,
              href: json.data.url,
            };

            const mdUpdate = {
              ...update,
              provider: json.data.provider_url,
            };

            const mdxUpdate = {
              ...update,
              providerUrl: json.provider_url,
              providerName: json.provider_name,
            };

            if (editor.props.useMDX) {
              Transforms.setNodes(editor, { ...mdxUpdate }, { at: path });
            } else {
              Transforms.setNodes(
                editor,
                {
                  ...mdUpdate,
                  data: { hName: 'embed', hProperties: mdUpdate },
                },
                { at: path },
              );
            }
          })
          .catch(() => {
            setUrlValidationMessage("We can't find anything to embed at that URL");
          });
      } catch (err) {
        setUrlValidationMessage('Invalid URL');
      }
    },
    [element.iframe, type, heightEntered, heightUnit, widthEntered, widthUnit, editor, path, setUrlValidationMessage],
  );

  const handleOnBlur = useCallback(
    event => {
      state.setUrlEntered(event.target.value);
      insertEmbed({ urlToUse: event.target.value });
    },
    [insertEmbed, state],
  );

  const handleOnKeyDown = useCallback(
    event => {
      if (!['Enter', 'Escape'].includes(event.key)) return;

      event.preventDefault();
      event.stopPropagation();

      insertEmbed({ urlToUse: event.target.value });
    },
    [insertEmbed],
  );

  const onChangeHeight = useCallback(
    event => {
      state.setHeight(event.target.value);
      insertEmbed({ urlToUse: urlEntered, inputHeight: event.target.value });
    },
    [insertEmbed, urlEntered, state],
  );

  const onChangeHeightUnit = useCallback(
    event => {
      const unit = state.sizeOptions[event.target.value - 1].label;
      state.setHeightUnit(unit);
      insertEmbed({ urlToUse: urlEntered, inputHeightUnit: event.target.value });
    },
    [insertEmbed, urlEntered, state],
  );

  const onChangeWidth = useCallback(
    event => {
      state.setWidth(event.target.value);
      insertEmbed({ urlToUse: urlEntered, inputWidth: event.target.value });
    },
    [insertEmbed, urlEntered, state],
  );

  const onChangeWidthUnit = useCallback(
    event => {
      const unit = state.sizeOptions[event.target.value - 1].label;
      state.setWidthUnit(unit);
      insertEmbed({ urlToUse: urlEntered, inputWidthUnit: event.target.value });
    },
    [insertEmbed, urlEntered, state],
  );

  return {
    insertEmbed,
    handleOnBlur,
    handleOnKeyDown,
    onChangeHeight,
    onChangeHeightUnit,
    onChangeWidth,
    onChangeWidthUnit,
  };
};
