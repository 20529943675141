import Component from './Component';
import normalizeNode from './normalizeNode';
import { serialize } from './serialize';
import { type } from './shared';

const JsxCommentToken = {
  Component,
  type,
  normalizeNode,
  hasInlineMd: true,
  isInline: true,
  serialize,
};

export default JsxCommentToken;
