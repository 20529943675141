/* eslint-disable consistent-return */
import { Transforms } from 'slate';

import type { Normalizer } from '@ui/MarkdownEditor/types';

import { isImage } from '../Image/shared';

import { isFigure } from './shared';

const removeEmpty: Normalizer = next => (editor, nodeEntry) => {
  const [node, path] = nodeEntry;
  if (!isFigure(node)) return next();
  if (isImage(node.children[0])) return next();

  Transforms.removeNodes(editor, { at: path });
};

export default [removeEmpty];
