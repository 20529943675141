import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import Flex from '@ui/Flex';
import Menu, { MenuDivider, MenuItem } from '@ui/Menu';

import classes from './style.module.scss';
import { tooltipItems } from './tooltipItems';

const SlashMenuTooltip = ({ menuRef }) => {
  const [itemName, setItemName] = useState(null);
  const tooltipItem = Object.values(tooltipItems).find(item => {
    const _itemName = item.label || item.block;
    return _itemName === itemName;
  });
  const imgPath = tooltipItem && `/public/img/dash/editor/${tooltipItem.block}.png`;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!menuRef.current) return;
    const item = menuRef.current.querySelector('[data-name]:hover');
    setItemName(item?.dataset.name);
  });

  return tooltipItem ? (
    <Menu className={classes.SlashMenuTooltip} theme="dark">
      <Flex align="stretch" gap="0" layout="col">
        <MenuItem focusable={false}>
          <img alt={tooltipItem.block} className={classes['SlashMenuTooltip-img']} src={imgPath} />
        </MenuItem>
        <Flex align="stretch" gap="0" layout="col">
          <MenuItem focusable={false}>{tooltipItem.block}</MenuItem>
          {!!tooltipItem.shortcut && (
            <>
              <MenuDivider />
              <MenuItem description="Shortcut" focusable={false} />
              <MenuItem focusable={false}>
                <kbd className={classes['SlashMenuTooltip-kbd']}>{tooltipItem.shortcut}</kbd>
              </MenuItem>
            </>
          )}
          {!!tooltipItem.description && (
            <>
              <MenuDivider />
              <MenuItem description={tooltipItem.description} focusable={false} />
            </>
          )}
        </Flex>
      </Flex>
    </Menu>
  ) : null;
};

SlashMenuTooltip.propTypes = {
  menuRef: PropTypes.shape({ current: PropTypes.any }),
};

export default SlashMenuTooltip;
