/* eslint-disable no-use-before-define */
import * as RDMD from '@readme/markdown';
import { Editor, Text } from 'slate';

import { blocksByType as blocks } from '@ui/MarkdownEditor/editor/byType';
import { NodeTypes } from '@ui/MarkdownEditor/enums';

import { isJsxCommentToken } from './blocks/JsxCommentToken/shared';
import log from './log';

const parseDecorators = (node, { renderingLibrary }) => {
  const string = node.children
    .map(child => {
      if (Text.isText(child)) return child.text;
      if (isJsxCommentToken(child)) return child.children[0].text;
      return serialize(child, { parent: node, renderingLibrary });
    })
    .join('');

  return toMdast(string, {
    renderingLibrary,
  });
};

const _deeper = (node, { renderingLibrary }) => {
  if (node.children && node.children.find(Text.isText)) {
    const mdast = parseDecorators(node, { renderingLibrary });
    return Array.isArray(mdast) ? mdast : [mdast];
  }

  return node.children.flatMap(child => slateToMdast(child, { parent: node, renderingLibrary }));
};

const toMdast = (str, { renderingLibrary }) => {
  /*
   * @note: slate is perfectly happy rendering newlines, but markdown treats a
   * plain `\n` similar to html, and collapses it down to a regular ` `. To get
   * around that, we escape the newline with a couple of spaces. Note that
   * escaping a newline with a backslash is not compatible with JSX, but two
   * spaces are.
   *
   * https://spec.commonmark.org/0.31.2/#hard-line-breaks
   */
  const string = 'mdx' in renderingLibrary ? str.replace(/\n/, '  \n') : str;
  try {
    const ast = renderingLibrary.mdast(string);

    if (ast?.children?.length) {
      return ast.children;
    }
  } catch (e) {
    // no-op
  }

  return {
    type: 'text',
    value: string,
  };
};

export const slateToMdast = (node, { parent, renderingLibrary }) => {
  const deeper = n => _deeper(n, { renderingLibrary });

  if (Text.isText(node)) {
    return toMdast(node.text, { renderingLibrary });
  } else if (Editor.isEditor(node) || !node.type) {
    return {
      type: 'root',
      children: deeper(node),
    };
  }

  switch (node.type) {
    case 'heading': {
      const children = node.children.map((child, idx) =>
        idx === 0 ? { text: child.text.replace(/^#+ ?/, '') } : child,
      );

      return {
        ...node,
        children: deeper({ children }),
      };
    }
    case 'hr':
      return {
        type: 'thematicBreak',
      };
    case 'root':
      return {
        type: 'root',
        children: deeper(node),
      };
    default: {
      const block = blocks[node.type];
      const mdNode = block.serialize
        ? block.serialize(node, deeper, { renderingLibrary, parent })
        : {
            ...node,
            children: deeper(node),
            type: block.rdmdType || node.type,
          };

      return mdNode;
    }
  }
};

const trimBreaks = root => {
  [0, 1].forEach(() => {
    while (root.children[0]?.type === NodeTypes.html && root.children[0].value.match(/^<br( \/)?>$/)) {
      root.children.shift();
    }

    root?.children?.reverse();
  });

  return root;
};

const serialize = (node, { parent = null, renderingLibrary = RDMD } = {}) => {
  let mdast = slateToMdast(node, { parent, renderingLibrary });

  if (!mdast.type && mdast.children) {
    mdast.type = 'root';
  } else if (mdast.type !== 'root') {
    mdast = { type: 'root', children: Array.isArray(mdast) ? mdast : [mdast] };
  }

  mdast = trimBreaks(mdast);

  log('serialize', { node, mdast });

  // @note: Not sure who's adding newlines and spaces (!?), but this is
  // innocuous enough.
  const string = renderingLibrary['mdx' in renderingLibrary ? 'mdx' : 'md'](mdast, {
    normalize: false,
  }).trimEnd();

  return string;
};

export default serialize;
