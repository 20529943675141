import {
  insertGistEmbed,
  insertIframeEmbed,
  insertJSFiddleEmbed,
  insertPDFEmbed,
  insertYouTubeEmbed,
} from './operations';

const slashMenu = {
  'YouTube Embed': { icon: 'youtube', transform: insertYouTubeEmbed },
  'GitHub Gist Embed': { icon: 'github', transform: insertGistEmbed },
  'PDF Embed': { icon: 'file', transform: insertPDFEmbed },
  'JSFiddle Embed': { icon: 'jsfiddle', transform: insertJSFiddleEmbed },
  'Iframe Embed': { icon: 'maximize', transform: insertIframeEmbed },
};

export default slashMenu;
