import type { Editor, Operation } from 'slate';

import { blocksByType as blocks } from '@ui/MarkdownEditor/editor/byType';
import * as selection from '@ui/MarkdownEditor/editor/selection';
import type { ApplyHandler } from '@ui/MarkdownEditor/types';

import inlineMdBounds from './inlineMdBounds';

// @note: `apply` is a very low level funciton in slate. All transforms get
// represented as an Operation and eventally get 'applied' to the editor via
// `apply`. Certain behaviors can only safely be enforced at this level, ie
// inserting text or normalizing the selection. Slate provides a hook at
// `editor.insertText` that's easily overridden. But it's also easily bypassed
// by calling `Transforms.insertText`. And for selection, there is currently no
// "selection normalizer".
//
// The apply handlers are expected to accept an editor and an operation and
// return either an operation or nullish. You can modify the operation or
// return as is and the operation will be applied as normal. If you return null
// the operation is skipped.
const apply = (editor: Editor) => {
  const { apply: original } = editor;

  const handlers: ApplyHandler[] = [
    ...selection.apply,
    ...inlineMdBounds,
    ...(Object.values(blocks as Record<string, { apply?: ApplyHandler }>)
      .filter(b => 'apply' in b)
      .flatMap(b => b.apply) as ApplyHandler[]),
  ];

  return (_op: Operation) => {
    let op: Operation | void = _op;

    for (const fn of handlers) {
      op = fn(editor, op);
      if (!op) return;
    }

    if (Array.isArray(op)) {
      op.forEach(operation => original(operation));
    } else {
      original(op);
    }
  };
};

export default apply;
