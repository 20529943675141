import { Node, Path, Editor, Transforms } from 'slate';

import emptyNode from '@ui/MarkdownEditor/emptyNode';
import type { ParagraphElement, ReadmeElement } from '@ui/MarkdownEditor/types';

import { isFigure, type as figureType } from '../Figure/shared';

import { type } from './shared';

export const insertFigcaption = (editor: Editor, imagePath: Path) => {
  const figure = Editor.above(editor, { at: imagePath, match: isFigure });
  const hasFigcaption = figure && Node.has(editor, Path.next(imagePath));

  if (figure && hasFigcaption) {
    Transforms.select(editor, Editor.end(editor, figure[1]));
    return;
  }

  const at = figure ? [...figure[1], 1] : [...imagePath, 1];

  Editor.withoutNormalizing(editor, () => {
    if (editor.props?.useMDX) {
      Transforms.setNodes(editor, { editCaption: true }, { at: imagePath });
    } else {
      if (!figure) {
        Transforms.wrapNodes(editor, { type: figureType } as ReadmeElement, { at: imagePath });
      }
      Transforms.insertNodes(editor, { type, children: [emptyNode() as ParagraphElement] }, { at, select: true });
    }
  });
};

export default { insertFigcaption };
