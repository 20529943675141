import React, { createContext, useState } from 'react';

import useEventListener from '../useEventListener';

export const IsMouseDownContext = createContext(false);

export const IsMouseDownProvider = ({ children }: { children: React.ReactNode }) => {
  const [isMouseDown, setIsMouseDown] = useState(false);
  useEventListener('mousedown', () => setIsMouseDown(true));
  useEventListener('mouseup', () => setIsMouseDown(false));
  useEventListener('blur', () => setIsMouseDown(false));

  return <IsMouseDownContext.Provider value={isMouseDown}>{children}</IsMouseDownContext.Provider>;
};
