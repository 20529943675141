import { useState, useEffect, useMemo } from 'react';

import { EmbedTypes } from '@ui/MarkdownEditor/enums';
import type { EmbedBlock, EmbedElement } from '@ui/MarkdownEditor/types';

const extractSize = (dimension?: string, unit?: string) => {
  return dimension?.match(unit ? /(px)|(%)|(em)*/ : /[0-9]*/)?.[0];
};

export const useEmbedState = (element: EmbedBlock | EmbedElement) => {
  const { height, width, url, iframe, typeOfEmbed = EmbedTypes.default } = element;

  const [type, setType] = useState<EmbedTypes>(typeOfEmbed);
  const [urlEntered, setUrlEntered] = useState(url || '');
  const [urlValidationMessage, setUrlValidationMessage] = useState('');

  const [heightEntered, setHeight] = useState(extractSize(height) || '300');
  const [widthEntered, setWidth] = useState(extractSize(width) || '100');
  const [heightUnit, setHeightUnit] = useState(extractSize(height, 'unit') || 'px');
  const [widthUnit, setWidthUnit] = useState(extractSize(width, 'unit') || '%');

  const sizeOptions = useMemo(
    () => [
      { label: 'px', value: 1 },
      { label: '%', value: 2 },
      { label: 'em', value: 3 },
    ],
    [],
  );

  // reset menu
  useEffect(() => {
    if (element) {
      setUrlValidationMessage('');
      setUrlEntered(url || '');
      setHeight(extractSize(height) || '300');
      setWidth(extractSize(width) || '100');
      setHeightUnit(extractSize(height, 'unit') || 'px');
      setWidthUnit(extractSize(width, 'unit') || '%');
    }
    // @note: only reset when the element changes???
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element]);

  return {
    type,
    setType,
    urlEntered,
    setUrlEntered,
    urlValidationMessage,
    setUrlValidationMessage,
    heightEntered,
    setHeight,
    widthEntered,
    setWidth,
    heightUnit,
    setHeightUnit,
    widthUnit,
    setWidthUnit,
    sizeOptions,
    iframe,
  };
};
