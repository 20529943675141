import type { ReadType as CustomBlockReadType } from '@readme/api/src/mappings/customblock/types';
import type { RenderElementProps } from 'slate-react';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Transforms } from 'slate';
import { HistoryEditor } from 'slate-history';
import { useSelected, useSlateStatic, ReactEditor } from 'slate-react';

import useClassy from '@core/hooks/useClassy';
import { useReadmeApiNext } from '@core/hooks/useReadmeApi';
import useUserPermissions from '@core/hooks/useUserPermissions';

// eslint-disable-next-line no-restricted-imports
import PageUsageMenu from '@routes/Dash/CustomBlocks/PageUsageMenu';

import Button from '@ui/Button';
import type { DropdownRef } from '@ui/Dropdown';
import Dropdown from '@ui/Dropdown';
import ErrorState from '@ui/ErrorState';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import type { ReusableContentElement } from '@ui/MarkdownEditor/types';
import Menu, { MenuDivider, MenuItem } from '@ui/Menu';
import RDMD from '@ui/RDMD';
import Spinner from '@ui/Spinner';

import classes from './style.module.scss';

interface ReusableContentProps extends RenderElementProps {
  element: ReusableContentElement;
}

function ReusableContent({ attributes, children, element }: ReusableContentProps) {
  const editor = useSlateStatic();
  const isSelected = useSelected();
  const bem = useClassy(classes, 'ReusableContent');
  const [isActive, setIsActive] = useState(false);
  const { customBlocks, version, parentSubdomain, subdomain } = editor.props;
  const { open: openModal } = editor.reusableContentModal;
  const { isGroupAdminUser: isGroupAdmin } = useUserPermissions();
  const dropdownRef = useRef<DropdownRef>(null);

  const initialCustomBlock = useMemo(
    () => customBlocks?.find(cb => cb.tag === element.tag),
    [customBlocks, element.tag],
  );

  const [{ body, name }, setDisplayData] = useState<{ body?: string; name?: string }>(() => ({
    body: initialCustomBlock?.source || '',
    name: initialCustomBlock?.name || '',
  }));

  const handleUpdate = useCallback(
    (update?: CustomBlockReadType) => {
      if (update) {
        const { tag } = update.data;

        // If the tag has changed, update the slate element's tag property.
        if (element.tag !== tag) {
          HistoryEditor.withoutSaving(editor, () => {
            const path = ReactEditor.findPath(editor, element);
            Transforms.setNodes(
              editor,
              {
                tag,
              },
              { at: path },
            );
          });
        }

        // Update the component display data.
        setDisplayData({
          body: update.data.source,
          name: update.data.name,
        });
      }
    },
    [editor, element],
  );

  const {
    data: requestData,
    isLoading: isRequestLoading,
    error,
    mutate,
  } = useReadmeApiNext<CustomBlockReadType>(
    element.tag ? `/versions/${version}/custom_blocks/${element.tag.toLowerCase()}` : null,
    {
      swr: {
        shouldRetryOnError: true,
        refreshInterval: 60 * 1000,
        revalidateOnFocus: true,
      },
    },
  );

  const isGlobal = requestData ? requestData?.data?.links?.project !== '/projects/me' : false;
  const groupMgmtLink = `/group/${parentSubdomain}/reusable-content/${element.tag}`;
  const projectMgmtLink = `/project/${subdomain}/v${version}/reusable-content/${element?.tag}`;
  const blockId = element.tag;

  useEffect(() => {
    handleUpdate(requestData);
    if (requestData) {
      editor.props?.onCustomBlockSave?.(requestData);
    }
  }, [requestData, handleUpdate, editor.props]);

  const handleDetatchContent = useCallback(() => {
    // remove the ReusableContent block element from the editor and replace with the markdown content
    const path = ReactEditor.findPath(editor, element);
    Transforms.removeNodes(editor, { at: path });
    Transforms.insertNodes(editor, editor.deserialize(body), { at: path });
  }, [body, editor, element]);

  const handleEditContent = useCallback(() => {
    openModal({
      item: requestData?.data,
      // Immediately update the element's inital request data
      // and revalidate the SWR cache when the modal saves.
      onSave: update => mutate(update),
      onDelete: () => {
        // If the modal deletes the content, remove the ReusableContent block element from the editor.
        const path = ReactEditor.findPath(editor, element);
        Transforms.removeNodes(editor, { at: path });
      },
    });
  }, [editor, element, mutate, openModal, requestData?.data]);

  // If the page has reusable content, but the user doesn't have permission to edit it,
  // we want to render the content as read-only.
  const isReadOnly = editor.props.useReusableContent === false;
  const readOnlyMessage = useMemo(() => {
    switch (editor.reusableContentMode) {
      case 'suggested-edits':
        return 'Content not editable when suggesting edits.';
      case 'no-plan-access':
        return 'Upgrade your plan to edit this content.';
      default:
        return null;
    }
  }, [editor.reusableContentMode]);

  const isInteractive = useMemo(() => !error && !!body, [body, error]);
  const noEditPermission = useMemo(
    () => isGlobal && !isGroupAdmin && !isReadOnly,
    [isGlobal, isGroupAdmin, isReadOnly],
  );

  const isLoading = useMemo(() => {
    const hasInitialData = !!body || !!name;
    return !hasInitialData && isRequestLoading;
  }, [body, isRequestLoading, name]);

  const usage = useMemo(() => {
    return {
      pageCount: requestData?.data?.usage?.page_count,
      projectCount: requestData?.data?.usage?.project_count,
    };
  }, [requestData]);

  const onToggle = useCallback(isOpen => {
    setIsActive(isOpen);
  }, []);

  return (
    <div
      className={bem(
        '&',
        isActive && '_active',
        isSelected && '_selected',
        isInteractive && '_interactive',
        isLoading && '_loading',
        isReadOnly && '_read-only',
      )}
      {...attributes}
      contentEditable={false}
      data-testid="reusable-content"
    >
      {!isLoading && (
        <div className={bem('-controls')}>
          {isInteractive ? (
            <Dropdown
              ref={dropdownRef}
              appendTo={document.body}
              justify="start"
              offset={[5, 2]}
              onToggle={onToggle}
              sticky
            >
              <Button className={bem('-config-menu-button')} kind={isReadOnly ? 'minimum' : 'success'}>
                <Icon className={bem('-config-menu-button-icon')} name="recycle" size="sm" strokeWeight={3} />
                <span className={bem('-config-menu-button-label')}>
                  {isGlobal ? 'GLOBAL ' : null}REUSABLE{!isReadOnly ? `: ${name}` : null}
                </span>
                <Icon name="chevron-down" size={14} strokeWeight={2.5} />
              </Button>
              <Menu>
                {!isReadOnly && (
                  <>
                    <MenuItem
                      disabled={!!isGlobal && !isGroupAdmin}
                      href={isGlobal ? groupMgmtLink : undefined}
                      icon={<Icon name="edit-2" strokeWeight={3} />}
                      onClick={!isGlobal ? handleEditContent : undefined}
                      TagName="a"
                      target="_blank"
                    >
                      <span>Edit</span>
                    </MenuItem>
                  </>
                )}

                <MenuItem icon={<Icon name="unlink" strokeWeight={3} />} onClick={handleDetatchContent}>
                  <span>Detach</span>
                </MenuItem>

                {!isGlobal && !isReadOnly && (
                  <>
                    <MenuDivider />
                    <MenuItem TagName={Link} to={projectMgmtLink}>
                      <span>Manage All Content</span>
                    </MenuItem>
                  </>
                )}

                {!!isReadOnly && (
                  <>
                    <MenuDivider />
                    <MenuItem description={readOnlyMessage} focusable={false} />
                  </>
                )}
              </Menu>
            </Dropdown>
          ) : (
            <div className={bem('-config-menu-button')}>
              <Icon name="recycle" size="sm" strokeWeight={3} />
              <span>REUSABLE</span>
            </div>
          )}
          {!isReadOnly && (
            <Flex align="center" className={bem('-controls-right')} gap={0}>
              {!!blockId && (
                <PageUsageMenu blockId={blockId} isGlobal={isGlobal} showProjectUsage={false} usageData={usage} />
              )}
            </Flex>
          )}
        </div>
      )}
      {isLoading ? (
        <Spinner />
      ) : !body && error ? (
        <ErrorState
          className={bem('-error')}
          message="There was a problem loading this content. Try refreshing your browser."
          title={null}
        />
      ) : body ? (
        <div className={bem('-preview-container')}>
          <button
            className={bem('-preview-button', noEditPermission && '-preview-button_blocked')}
            disabled={isReadOnly || !isInteractive || isGlobal}
            id="preview-button"
            onClick={handleEditContent}
            type="button"
          >
            <figure className={bem('-preview')} inert="">
              <RDMD body={body} opts={{ mdx: editor.props.useMDX }} />
            </figure>
          </button>
          {!!noEditPermission && (
            <div className={bem('-permissions-warning')} id="permissions-warning">
              <div className={bem('-permissions-warning-container')}>
                <h3>No Edit Permission</h3>
                <p className={bem('-permissions-warning-message')}>
                  You need Group Admin permissions to edit global content. Continue to use the content as is or detach
                  and edit separately.
                </p>
              </div>
            </div>
          )}
        </div>
      ) : null}
      {children}
    </div>
  );
}

export default ReusableContent;
