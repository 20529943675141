import React, { useCallback, useEffect } from 'react';

import { EmbedTypes } from '@ui/MarkdownEditor/enums';
import type { EmbedBlock, EmbedElement } from '@ui/MarkdownEditor/types';
import Menu, { MenuHeader } from '@ui/Menu';
import MenuDivider from '@ui/Menu/Divider';
import MenuItem from '@ui/Menu/Item';

import { useEmbedHandlers } from '../hooks/useEmbedHandlers';
import { useEmbedState } from '../hooks/useEmbedState';
import classes from '../style.module.scss';

import EmbedSizeInput from './EmbedSizeInput';
import EmbedURLInput from './EmbedURLInput';

interface Props {
  element: EmbedBlock | EmbedElement;
}

export const EmbedEditor = ({ element }: Props) => {
  const state = useEmbedState(element);
  const {
    insertEmbed,
    handleOnBlur,
    handleOnKeyDown,
    onChangeHeight,
    onChangeHeightUnit,
    onChangeWidth,
    onChangeWidthUnit,
  } = useEmbedHandlers(element, state);

  const [urlLabel, placeholder] =
    state.type === 'youtube'
      ? ['YouTube URL', 'https://www.youtube.com/']
      : state.type === 'github'
        ? ['GitHub Gist URL', 'https://gist.github.com/']
        : state.type === 'pdf'
          ? ['PDF URL', 'https://pdf-sample.pdf']
          : state.type === 'jsfiddle'
            ? ['JSFiddle URL', 'https://jsfiddle.net/']
            : state.type === 'iframe' || element.iframe
              ? ['Iframe URL', 'https://readme.com/']
              : ['Embed URL', 'https://readme.com/'];

  useEffect(() => {
    insertEmbed({ urlToUse: state.urlEntered });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.type]);

  const onChangeType = useCallback(
    newType => {
      state.setType(newType);
    },
    [state],
  );

  return (
    <Menu className={classes['Settings-Menu']} role="menu">
      <MenuItem focusable={false}>
        <EmbedURLInput
          handleOnBlur={handleOnBlur}
          handleOnKeyDown={handleOnKeyDown}
          placeholder={placeholder}
          urlEntered={state.urlEntered}
          urlLabel={urlLabel}
          urlValidationMessage={state.urlValidationMessage}
        />
      </MenuItem>
      {element.iframe || state.type === 'iframe' ? (
        <>
          <MenuDivider />
          <MenuItem focusable={false}>
            <EmbedSizeInput
              label="Height"
              onChange={onChangeHeight}
              onChangeUnit={onChangeHeightUnit}
              sizeOptions={state.sizeOptions}
              unit={state.heightUnit}
              value={state.heightEntered}
            />
          </MenuItem>
          <MenuDivider />
          <MenuItem focusable={false}>
            <EmbedSizeInput
              label="Width"
              onChange={onChangeWidth}
              onChangeUnit={onChangeWidthUnit}
              sizeOptions={state.sizeOptions}
              unit={state.widthUnit}
              value={state.widthEntered}
            />
          </MenuItem>
        </>
      ) : null}
      {state.type === 'default' && !element.iframe ? (
        <>
          <MenuDivider />
          <MenuHeader>Convert Embed to:</MenuHeader>
          {[
            { type: EmbedTypes.youtube, label: 'Youtube' },
            { type: EmbedTypes.github, label: 'GitHub Gist' },
            { type: EmbedTypes.pdf, label: 'PDF' },
            { type: EmbedTypes.jsfiddle, label: 'JSFiddle' },
            { type: EmbedTypes.iframe, label: 'Iframe' },
          ].map(({ type, label }) => (
            <MenuItem key={type} onClick={() => onChangeType(type)}>
              {label}
            </MenuItem>
          ))}
        </>
      ) : null}
      <MenuDivider />
    </Menu>
  );
};

export default EmbedEditor;
