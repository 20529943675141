import * as RDMD from '@readme/markdown';

import type { Deserializer, ReusableContentElement, ReusableContentMdNode, Serializer } from '@ui/MarkdownEditor/types';

import { type } from './shared';

export const deserialize: Deserializer<ReusableContentMdNode, ReusableContentElement> = node => {
  const newNode = {
    type,
    tag: node.tag,
    ...(node.children.length > 0 && { source: RDMD.md({ children: node.children, type: 'root' }) }),
    children: [{ text: '' }] as [{ text: '' }],
  };

  return newNode;
};

export const serialize: Serializer<ReusableContentElement, ReusableContentMdNode> = node => {
  return {
    children: [],
    type: 'reusable-content',
    tag: node.tag,
  };
};
